// File generated automatically, do not modify

export enum ActionEntityType {
  Itc = 1,
  ItcServiceRequestTimeProfile = 2,
  Vms = 3,
  ParkingSection = 4,
  Parking = 5,
  UrbioticaOrganism = 6,
  UrbioticaProject = 7,
  ParkingSensor = 8,
  ParkingSystemLog = 9,
  OutputDevice = 10,
}

export const actionEntityTypeDescription = {
  [ActionEntityType.Itc]: 'Sterownik',
  [ActionEntityType.ItcServiceRequestTimeProfile]: 'Profil czasu przyjęcia/naprawy zgłoszenia',
  [ActionEntityType.Vms]: 'VMS',
  [ActionEntityType.ParkingSection]: 'Segment parkingowy',
  [ActionEntityType.Parking]: 'Parking',
  [ActionEntityType.UrbioticaOrganism]: 'Urbiotica organism',
  [ActionEntityType.UrbioticaProject]: 'Urbiotica project',
  [ActionEntityType.ParkingSensor]: 'Czujnik miejsca parkingowego',
  [ActionEntityType.ParkingSystemLog]: 'Wpis logów systemu parkingowego',
  [ActionEntityType.OutputDevice]: 'Urządzenie wyjściowe',
};

export interface ActionLog {
  id: number;
  timestamp: number;
  type: ActionType;
  user?: IdLabel;
  impersonatedUser?: IdLabel;
  itc?: IdLabel;
  procedure?: Procedure;
  entityId?: number;
  entityType?: ActionEntityType;
  entityChangeType?: EntityChangeType;
  details?: unknown;
  diff?: ActionLogDiff;
  generatedDescription: string;
}

export interface ActionLogCriteria {
  dateFrom?: number;
  dateTo?: number;
  userId?: number;
  tenantId?: number;
  itcIds?: number[];
  actionType?: ActionType;
  entityType?: ActionEntityType;
  entityChangeTypes?: EntityChangeType[];
  page?: number;
  pageSize?: number;
}

export interface ActionLogDiff {
  items: ActionLogDiffItem[];
}

export interface ActionLogDiffChange {
  label: string;
  oldValue: string;
  newValue: string;
  translateValue?: boolean;
}

export interface ActionLogDiffItem {
  label?: string;
  changeType?: EntityChangeType;
  changes: ActionLogDiffChange[];
}

export interface ActionLogEvent {
  type: ActionType;
  tenantId?: number;
  userId?: number;
  impersonatedUserId?: number;
  itcId?: number;
  procedureId?: number;
  entityChangeType?: EntityChangeType;
  entityType?: ActionEntityType;
  entityId?: number;
  details?: unknown;
  diff?: ActionLogDiff;
}

export interface ActionLogViewResponse {
  totalCount: number;
  providedCount: number;
  items: ActionLog[];
}

export enum ActionType {
  OpenItcDetails = 1,
  WsExecuteProcedure = 2,
  WsPlanChange = 3,
  WsSignStateChange = 4,
  WsHoldGroupState = 5,
  WsDisconnectItc = 6,
  WsControllerLogs = 7,
  PplanChange = 8,
  ItcPlanChangeByScenario = 9,
  WsErrorReset = 10,
  WsLampLoad = 11,
  WsReloadConfig = 14,
  UserLogin = 15,
  GuiVersionSwitch = 16,
  WsStripeArchive = 17,
  UserImpersonate = 20,
  BruteForceProtectionActivated = 30,
  CrudEntityChange = 1000,
}

export const actionTypeDescription = {
  [ActionType.OpenItcDetails]: 'Otwarcie okna sterownika',
  [ActionType.WsExecuteProcedure]: 'Uruchomienie/wyłączenie procedury',
  [ActionType.WsPlanChange]: 'Zmiana planu sterownika',
  [ActionType.WsSignStateChange]: 'Zmiana stanu sygnalizacji',
  [ActionType.WsHoldGroupState]: 'Wstrzymanie grupy sygnałowej',
  [ActionType.WsDisconnectItc]: 'Odłączenie/podłączenie sterownika do systemu',
  [ActionType.WsControllerLogs]: 'Pobranie logów sterownika',
  [ActionType.PplanChange]: 'Zmiana wartości pplan',
  [ActionType.ItcPlanChangeByScenario]: 'Zmiana planu sterownika przez scenariusz',
  [ActionType.WsErrorReset]: 'Reset błędów sterownika',
  [ActionType.WsLampLoad]: 'Pobranie obciążenia lamp',
  [ActionType.WsReloadConfig]: 'Przeładowanie konfiguracji sterownika',
  [ActionType.UserLogin]: 'Logowanie do systemu',
  [ActionType.GuiVersionSwitch]: 'Przełączenie interfejsu aplikacji',
  [ActionType.WsStripeArchive]: 'Pobranie archiwalnego wykresu paskowego',
  [ActionType.UserImpersonate]: 'Serwisowe wcielenie się w rolę innego użytkownika',
  [ActionType.BruteForceProtectionActivated]: 'Aktywacja ochrony operacji logowania',
  [ActionType.CrudEntityChange]: 'Utworzenie/zmiana/usunięcie obiektu',
};

export interface AppCondition {
  creationDate: string;
  messages: AppConditionMessage[];
  hasErrors: boolean;
  hasWarnings: boolean;
}

export interface AppConditionMessage {
  type: AppConditionMessageType;
  superUserOnly: boolean;
  category?: string;
  itc?: IdLabel;
  text: string;
}

export enum AppConditionMessageType {
  Info = 0,
  Warning = 1,
  Error = 2,
}

export const appConditionMessageTypeDescription = {
  [AppConditionMessageType.Info]: 'Informacja',
  [AppConditionMessageType.Warning]: 'Ostrzeżenie',
  [AppConditionMessageType.Error]: 'Błąd',
};

export interface AppLogs {
  totalCount: number;
  providedCount: number;
  items: AppLogsEntry[];
}

export interface AppLogsCriteria {
  dateFrom?: number;
  dateTo?: number;
  levels?: string[];
  minimumLevel?: string;
  messageTemplate?: string;
  message?: string;
  page?: number;
  pageSize?: number;
}

export interface AppLogsEntry {
  id: number;
  timestamp: number;
  level?: string;
  message?: string;
  messageTemplate?: string;
  exception?: string;
  properties?: string;
}

export interface AppLogsStats {
  messageStats: AppLogsStatsMessageStat[];
  levelStats: AppLogsStatsLevelStat[];
}

export interface AppLogsStatsLevelStat {
  level?: string;
  count: number;
}

export interface AppLogsStatsMessageStat {
  level?: string;
  messageTemplate?: string;
  count: number;
}

export interface AtkiDevice {
  id: number;
  tenant: IdLabel;
  externalId: string;
  status?: AtkiDeviceStatus;
  type?: AtkiDeviceType;
  gpsLocation: GpsCoords;
  application?: string;
  softwareVersion?: string;
  lastStatusReceivedAt: number;
  lastDataReceivedAt: number;
  removed: boolean;
  removedAt?: number;
  parkingSection?: IdLabel;
  issues: AtkiDeviceIssue[];
}

export interface AtkiDeviceIssue {
  id: number;
  timestamp: number;
  message: string;
}

export interface AtkiDeviceMapData {
  id: number;
  tenantId: number;
  status?: AtkiDeviceStatus;
  type?: AtkiDeviceType;
  lastStatusReceivedAt: number;
  gpsLocation: GpsCoords;
}

export interface AtkiDeviceSnapshot {
  exists: boolean;
  id?: number;
  timestamp: number;
  base64ImageData?: string;
}

export enum AtkiDeviceStatus {
  Ok = 1,
  Warn = 2,
  Error = 3,
}

export const atkiDeviceStatusDescription = {
  [AtkiDeviceStatus.Ok]: 'Praca prawidłowa',
  [AtkiDeviceStatus.Warn]: 'Ostrzeżenie',
  [AtkiDeviceStatus.Error]: 'Błąd',
};

export enum AtkiDeviceType {
  AvdSingleSpace = 1,
  AvdGatekeeper = 2,
}

export interface AtkiIntegrationMessage {
  id: number;
  device?: IdLabel;
  createdAt: number;
  durationMs: number;
  payload: string;
  eventType?: string;
  responseStatus: number;
  processedAt?: number;
  processingComment?: string;
  keepToAnalyze?: boolean;
  processingError?: string;
}

export interface AtkiIntegrationMessageCriteria {
  dateFrom?: number;
  dateTo?: number;
  beforeId?: number;
  deviceId?: number;
  responseStatus?: number;
  onlyWithErrors?: boolean;
  keepToAnalyze?: boolean;
  processingError?: string;
  payload?: string;
  eventType?: string;
  page?: number;
  pageSize?: number;
}

export interface AtkiIntegrationMessagesResponse {
  totalCount: number;
  providedCount: number;
  items: AtkiIntegrationMessage[];
}

export interface AuthInfo {
  user: AuthInfoUser;
  impersonatingUser?: IdLabel;
  privs: string[];
}

export interface AuthInfoUser {
  id: number;
  singleTenantId?: number;
  login: string;
  label: string;
  type: UserType;
  expiryTime?: number;
}

export interface BooleanSingleValue {
  value: boolean;
}

export interface Bus {
  id: number;
  fleetNr: string;
  gpsLocation: GpsCoords;
  vehicleType: VehicleType;
  plate: string;
  busDelay: number;
  delayLevel: BusDelayLevel;
  lastUpdate: number;
  connectionLost?: boolean;
  line?: BusLine;
}

export interface BusCriteria {
  busId?: number;
  hasLine?: boolean;
  lineNumber?: string;
  disableSorting?: boolean;
  cityId?: number;
}

export enum BusDelayLevel {
  None = 0,
  BeforeTime = 10,
  LowDelay = 20,
  MediumDelay = 30,
  HighDelay = 40,
  NotRelated = 98,
  Unknown = 99,
}

export const busDelayLevelDescription = {
  [BusDelayLevel.None]: 'Brak opóźnienia',
  [BusDelayLevel.BeforeTime]: 'Przed czasem',
  [BusDelayLevel.LowDelay]: 'Małe opóźnienie',
  [BusDelayLevel.MediumDelay]: 'Średnie opóźnienie',
  [BusDelayLevel.HighDelay]: 'Duże opóźnienie',
  [BusDelayLevel.NotRelated]: 'Nie dotyczy',
  [BusDelayLevel.Unknown]: 'Nieznane',
};

export interface BusLine {
  id: number;
  name: string;
  systemId?: number;
  systemName?: string;
}

export interface BusModuleReloadConfigInfo {
  cityId?: number;
  cityName?: string;
  lastWrite?: number;
  lastRead?: number;
}

export interface BusPrediction {
  id: number;
  fleetNr: string;
  gpsLocation: GpsCoords;
  vehicleType: VehicleType;
  plate: string;
  busDelay: number;
  delayLevel: BusDelayLevel;
  lastUpdate: number;
  line?: string;
  distance: number;
  timeToCrs: number;
}

export interface BusPredictionLiveData {
  crs: IdLabel;
  fleetNr: string;
  busLine: string;
  delay: number;
  enterZoneId: number;
  exitZoneId: number;
  distance: number;
  timeToCrs: number;
  predLat: number;
  predLng: number;
  wh: number;
}

export interface BusPriorityRowHeader {
  id: string;
  label?: string;
  crsId?: number;
  crsName?: string;
  busLineId?: number;
  busLine?: string;
  busSystemId?: number;
  busSystemName?: string;
  busId?: number;
  busFleetNr?: string;
  busPlate?: string;
  enterZoneId?: number;
}

export interface BusPriorityStatsColumn {
  id: string;
  label: string;
}

export enum BusPriorityStatsColumnDistinction {
  none = 0,
  priortyType = 1,
  predictionStatus = 2,
}

export const busPriorityStatsColumnDistinctionDescription = {
  [BusPriorityStatsColumnDistinction.none]: 'Brak',
  [BusPriorityStatsColumnDistinction.priortyType]: 'Rodzaj priorytetu',
  [BusPriorityStatsColumnDistinction.predictionStatus]: 'Status predykcji',
};

export interface BusPriorityStatsCriteria {
  dateStart?: string;
  dateEnd?: string;
  cityId?: number;
  itcIds?: number[];
  busLineIds?: number[];
  busIds?: number[];
  enterZoneId?: number;
}

export interface BusPriorityStatsParams {
  criteria: BusPriorityStatsCriteria;
  rowDistinction: BusPriorityStatsRowDistinction;
  columnDistinction: BusPriorityStatsColumnDistinction;
}

export interface BusPriorityStatsResult {
  columns: BusPriorityStatsColumn[];
  rows: BusPriorityStatsRow[];
}

export interface BusPriorityStatsRow {
  header: BusPriorityRowHeader;
  values: any;
}

export interface BusPriorityStatsRowDistinction {
  crossing: boolean;
  enterZone: boolean;
  busLine: boolean;
  busVehicle: boolean;
}

export interface BusZone {
  id: number;
  kind: ZoneKind;
  crsId: number;
  points: GpsCoords[];
  pointsHash: number;
  busPriority: BusZonePriority;
}

export enum BusZonePriority {
  noBus = 0,
  high = 1,
  medium = 2,
  low = 3,
  noPriority = 4,
}

export const busZonePriorityDescription = {
  [BusZonePriority.noBus]: 'Brak pojazdu w strefie',
  [BusZonePriority.high]: 'Wysoki priorytet pojazdu',
  [BusZonePriority.medium]: 'Średni priorytet pojazdu',
  [BusZonePriority.low]: 'Niski priorytet pojazdu',
  [BusZonePriority.noPriority]: 'Brak priorytetu pojazdu',
};

export interface BusZonePriorityDetails {
  id: number;
  crsId: number;
  crossingName: string;
  crsZoneIds: number[];
  priorityEntries: BusZonePriorityDetailsEntry[];
}

export interface BusZonePriorityDetailsEntry {
  id: number;
  enterZoneId: number;
  exitZoneId: number;
  ioOffset: number;
}

export interface CalendarDay {
  id: number;
  tenant?: IdLabel;
  day: number;
  month: number;
  year: number;
  kind: CalendarDayKind;
  comment?: string;
}

export enum CalendarDayKind {
  Holidays = 1,
}

export const calendarDayKindDescription = {
  [CalendarDayKind.Holidays]: 'Dzień wolny',
};

export enum CamApiType {
  Hikvision = 1,
}

export const camApiTypeDescription = {
  [CamApiType.Hikvision]: 'Hikvision',
};

export interface CamAutoSnapshotConfig {
  entries: CamAutoSnapshotConfigEntry[];
}

export interface CamAutoSnapshotConfigEntry {
  id: number;
  name?: string;
  itcName?: string;
  rtspUrl?: string;
  snapshotAutoCollect: boolean;
  snapshotAutoCollectMinutesInterval: number;
}

export interface CamLastSnapshot {
  cam: TrafficCam;
  last24HoursSnapshotEntry?: CamSnapshotEntry;
  last24HoursValidSnapshotEntry?: CamSnapshotEntry;
}

export interface CamNeededResponse {
  camId: string;
}

export interface CamSnapshotData {
  base64Data: string;
}

export interface CamSnapshotDetails {
  snapshotEntry: CamSnapshotEntry;
  ffmpegOutput?: string;
  snapshotData?: CamSnapshotData;
}

export interface CamSnapshotEntry {
  id: number;
  requestedAt: number;
  takenAt: number;
  takeDurationMs: number;
  error?: string;
  hasData: boolean;
  dataSize?: number;
  cam: IdLabel;
  itc?: IdLabel;
  region?: string;
  roadName?: string;
  roadLocationMark?: string;
  direction?: string;
}

export interface CamSnapshotEntryCriteria {
  dateStart?: number;
  dateEnd?: number;
  camIds?: number[];
  page?: number;
  pageSize?: number;
}

export interface CamSnapshotEntryResult {
  totalCount: number;
  providedCount: number;
  items: CamSnapshotEntry[];
}

export interface CamTestRequest {
  camUrl: string;
  returnFromCacheIfPossible?: boolean;
}

export interface CamTestResponse {
  videoCodec?: string;
  ffmpegOutput?: string;
  error?: CamTestResponseErrorData;
}

export interface CamTestResponseErrorData {
  code: string;
  message: string;
}

export interface Changelog {
  version: string;
  changes: string[];
}

export interface ChargingStation {
  id: number;
  tenant?: IdLabel;
  name?: string;
  webInterfaceUrl?: string;
  gpsLocation: GpsCoords;
}

export interface City {
  id: number;
  name: string;
  border?: string;
  tenants: IdLabel[];
}

export interface CityDiagnosticPoint {
  lat: number;
  lng: number;
  isInsideBoundary: boolean;
}

export interface CityDiagnosticPointsParams {
  mapBounds?: MapBounds;
  boundaryPoints: GpsCoords[];
}

export interface ClientExposedConfig {
  isBusModuleEnabled: boolean;
  isMeasurePointsEnabled: boolean;
  overrideDefaultWebsocketPath?: string;
  defaultMapCenter: ClientExposedConfigMapDefaultCenter;
  serviceMode: boolean;
  smsNotificationAvailable: boolean;
  emailNotificationAvailable: boolean;
  trafficPointsProxySiteUrl?: string;
  rtspProxyAvailable: boolean;
  rtspProxyUrl?: string;
  mseWsStreamServiceAvailable: boolean;
  scatsIntegrationEnabled: boolean;
  busDelayConfig: ClientExposedConfigBusDelayConfig;
  autoLogoutMinutes: number;
  brandingEnabled: boolean;
  brandName?: string;
  userConfig: ClientExposedUserConfig;
}

export interface ClientExposedConfigBusDelayConfig {
  lowPriorityMinDelay: number;
  mediumPriorityMinDelay: number;
  highPriorityMinDelay: number;
}

export interface ClientExposedConfigMapDefaultCenter {
  lat: number;
  lng: number;
  zoom: number;
}

export interface ClientExposedUserConfig {
  sideMenuEdgeTriggerBehaviour: SideMenuEdgeTriggerBehaviour;
}

export interface ContextHelpArticle {
  id: number;
  code: string;
  hasContent: boolean;
  url?: string;
  helpText?: string;
}

export interface ContextHelpMetadata {
  code: string;
  hasContent: boolean;
  url?: string;
  contextHelpArticleId?: number;
}

export interface CoordChartConfig {
  id: number;
  name: string;
  speed?: number;
  junctions: CoordChartConfigJunction[];
}

export interface CoordChartConfigJunction {
  crsId: number;
  groupLeftNr?: number;
  groupRightNr?: number;
  innerDistance: number;
  nextJunctionDistance: number;
}

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export interface DbAudit {
  id: number;
  auditDateTime: number;
  auditType: DbAuditType;
  userId?: number;
  userName: string;
  tableName: string;
  key?: number;
  keyObj?: string;
  properties: DbAuditProperty[];
}

export interface DbAuditCriteria {
  dbKind: string;
  dateFrom?: number;
  dateTo?: number;
  userIds?: number[];
  keys?: number[];
  keyObj?: string;
  auditTypes?: DbAuditType[];
  tableNames?: string[];
  propertyNames?: string[];
  page?: number;
  pageSize?: number;
}

export interface DbAuditProperty {
  id: number;
  name: string;
  oldValue?: string;
  newValue?: string;
}

export interface DbAuditResponse {
  totalCount: number;
  providedCount: number;
  items: DbAudit[];
}

export enum DbAuditType {
  None = 0,
  Create = 1,
  Update = 2,
  Delete = 3,
}

export interface DbContextModelMetadata {
  database: string;
  entities: DbContextModelMetadataEntity[];
}

export interface DbContextModelMetadataColumnMetadata {
  name: string;
  type: string;
  isNullable: boolean;
  allowNotNullOnColumnAnnotation: boolean;
}

export interface DbContextModelMetadataEntity {
  name: string;
  tableName: string;
  properties: DbContextModelMetadataProperty[];
}

export interface DbContextModelMetadataProperty {
  name: string;
  type: string;
  column: DbContextModelMetadataColumnMetadata;
}

export interface EmailLog {
  id: number;
  createdAt: number;
  sentAt?: number;
  recipient: string;
  subject: string;
  html: string;
  attachmentFileId?: number;
}

export interface EmailSendTestCommand {
  recipients: string;
  subject: string;
  content: string;
}

export enum EntityChangeType {
  Created = 1,
  Updated = 2,
  Deleted = 3,
}

export const entityChangeTypeDescription = {
  [EntityChangeType.Created]: 'Utworzenie',
  [EntityChangeType.Updated]: 'Modyfikacja',
  [EntityChangeType.Deleted]: 'Usunięcie',
};

export interface EnvironmentControllerHeartbeatResponse {
  serverTime: string;
  serverTimeUnix: number;
}

export interface EventDependentWork {
  description: string;
  key: unknown;
  trigger?: string;
  dispatchCount: number;
}

export interface FileListResponse {
  totalCount: number;
  providedCount: number;
  items: FileMetadata[];
}

export interface FileMetadata {
  id: number;
  name: string;
  mediaType: string;
  size: number;
  category: string;
  description: string;
  createdAt: number;
  userId?: number;
  user?: IdLabel;
  removed?: boolean;
  removedAt?: number;
  crsId?: number;
  crs?: IdLabel;
  itcServiceRequestAttachment: boolean;
}

export interface FileStreamHandleResponse {
  handleId: string;
}

export interface FileUpdateParams {
  id: number;
  itcId?: number;
  category?: string;
  description?: string;
}

export interface FileUploadParams {
  name: string;
  itcId?: number;
  category?: string;
  description?: string;
  base64?: string;
}

export interface FilesCriteria {
  itcIds?: number[];
  itcServiceRequestId?: number;
  userId?: number;
  category?: string;
  page?: number;
  pageSize?: number;
}

export interface FlowAnalytics {
  id: number;
  blockId: number;
  name: string;
  blockDescription: string;
}

export interface FlowAnalyticsIntegrationDetails {
  id: number;
  blockId: number;
  itcId?: number;
  name: string;
  blockDescription: string;
  integrationState: FlowIntegrationState;
  outdatedLastSync: boolean;
  sinks: FlowSinkIntegrationDetails[];
}

export interface FlowAnalyticsMapData {
  id: number;
  tenantId: number;
  blockId: number;
  itcId?: number;
  name: string;
  blockDescription: string;
  gpsLocation: GpsCoords;
  integrationState: FlowIntegrationState;
  outdatedLastSync?: boolean;
}

export interface FlowBlock {
  id: number;
  tenant?: IdLabel;
  blockName: string;
  description: string;
  apiAddress: string;
  mobile: boolean;
  removed: boolean;
  itc?: IdLabel;
}

export interface FlowBlockConnectTestResponse {
  success: boolean;
  error?: string;
  apiVersion?: string;
  blockHostDeviceType?: string;
  blockSpecialization?: string;
  blockVersion?: string;
  blockName?: string;
  cubes?: string[];
}

export interface FlowBlockManage {
  id: number;
  tenant?: IdLabel;
  blockName: string;
  description: string;
  apiAddress: string;
  mobile: boolean;
  removed: boolean;
  itc?: IdLabel;
  authToken: string;
  disableStrictAnaylitcsTimeSyncVerification: boolean;
}

export interface FlowBlockMapData {
  id: number;
  tenantId: number;
  itcId?: number;
  description: string;
  mobile: boolean;
  gpsLocation: GpsCoords;
}

export interface FlowBlockStructure {
  id: number;
  tenant?: IdLabel;
  blockName: string;
  description: string;
  apiAddress: string;
  mobile: boolean;
  itc?: IdLabel;
  cubes: FlowBlockStructureCube[];
}

export interface FlowBlockStructureAnalytics {
  id: number;
  externalId: number;
  name: string;
}

export interface FlowBlockStructureCube {
  id: number;
  externalId: number;
  name: string;
  analytics: FlowBlockStructureAnalytics[];
}

export enum FlowGlobalWebhookKind {
  TrafficEvent = 1,
}

export const flowGlobalWebhookKindDescription = {
  [FlowGlobalWebhookKind.TrafficEvent]: 'Zdarzenie ruchu drogowego',
};

export interface FlowGlobalWebhookUrl {
  kind: FlowGlobalWebhookKind;
  webhookUrl?: string;
  errorFeedback?: string;
}

export interface FlowGlobalWebhookUrlCreateParams {
  flowBlockId: number;
  fillApiKey: boolean;
  kind: FlowGlobalWebhookKind;
  attachTrafficEventVideo?: boolean;
}

export interface FlowIntegrationMessage {
  id: number;
  block?: IdLabel;
  sink?: IdLabel;
  createdAt: number;
  durationMs: number;
  method: string;
  url: string;
  authTokenHash?: string;
  hasRequestPayload: boolean;
  responseStatus: number;
  hasResponseData: boolean;
  processedAt?: number;
  processingComment?: string;
  processingError?: string;
}

export interface FlowIntegrationMessageCriteria {
  dateFrom?: number;
  dateTo?: number;
  blockId?: number;
  sinkId?: number;
  url?: string;
  responseStatus?: number;
  onlyWithErrors?: boolean;
  processingError?: string;
  responseData?: string;
  requestPayload?: string;
  page?: number;
  pageSize?: number;
}

export interface FlowIntegrationMessageDetails {
  id: number;
  block?: IdLabel;
  sink?: IdLabel;
  createdAt: number;
  durationMs: number;
  method: string;
  url: string;
  authTokenHash?: string;
  requestPayload?: string;
  responseStatus: number;
  responseData?: string;
  processedAt?: number;
  processingComment?: string;
  processingError?: string;
}

export interface FlowIntegrationMessagesResponse {
  totalCount: number;
  providedCount: number;
  items: FlowIntegrationMessage[];
}

export enum FlowIntegrationState {
  IntegrationDisabled = 0,
  Ok = 1,
  Errors = 2,
}

export const flowIntegrationStateDescription = {
  [FlowIntegrationState.IntegrationDisabled]: 'Integracja wyłączona',
  [FlowIntegrationState.Ok]: 'Brak błędów',
  [FlowIntegrationState.Errors]: 'Błędy integracji',
};

export interface FlowMapObjectsList {
  blocks: FlowBlockMapData[];
  analytics: FlowAnalyticsMapData[];
}

export interface FlowOdMatrixCartogramConfig {
  id: number;
  name: string;
  config: string;
  flowSinkId?: number;
}

export interface FlowOdMatrixCriteria {
  dateStart: number;
  dateEnd: number;
  sinkId: number;
}

export interface FlowOdMatrixSink {
  sinkId: number;
  analyticsId: number;
  blockId: number;
  itcId?: number;
  itcName?: string;
  sinkName: string;
  analyticsName: string;
  blockDescription: string;
}

export interface FlowOdMatrixStats {
  criteria: FlowOdMatrixCriteria;
  originGates: FlowOdMatrixStatsGate[];
  destinationGates: FlowOdMatrixStatsGate[];
  items: FlowOdMatrixStatsItem[];
}

export interface FlowOdMatrixStatsGate {
  id: number;
  label: string;
  totalCount: number;
}

export interface FlowOdMatrixStatsItem {
  originGateId: number;
  destinationGateId: number;
  count: number;
  countPerHour: number;
  totalOriginGateCount: number;
  originGatePercentage: number;
  subItems: FlowOdMatrixStatsSubItem[];
}

export interface FlowOdMatrixStatsSubItem {
  category: string;
  count: number;
  countPerHour: number;
}

export interface FlowOdMatrixTimelineStats {
  categories: IdLabel[];
  items: FlowOdMatrixTimelineStatsItem[];
}

export interface FlowOdMatrixTimelineStatsDestinationData {
  destinationGate: IdLabel;
  timePeriods: FlowOdMatrixTimelineStatsTimePeriod[];
}

export interface FlowOdMatrixTimelineStatsItem {
  originGate: IdLabel;
  destinations: FlowOdMatrixTimelineStatsDestinationData[];
}

export interface FlowOdMatrixTimelineStatsTimePeriod {
  whStart: number;
  whEnd: number;
  totalPer15Minutes: number;
  totalPerHour?: number;
  counts: FlowOdMatrixTimelineStatsVehicleCount[];
}

export interface FlowOdMatrixTimelineStatsVehicleCount {
  categoryId: number;
  count: number;
}

export interface FlowPreRemovalSummary {
  blockId: number;
  description: string;
  apiAddress: string;
  odMatrixRowsCount: number;
  distributionRowsCount: number;
  integrationMessagesCount: number;
  sinksCount: number;
}

export interface FlowSinkCompatibilityInfo {
  isCompatible: boolean;
  incompatibleReason?: string;
}

export interface FlowSinkDetails {
  id: number;
  externalId: number;
  name: string;
  sequenceNumber: string;
  outputValueType: string;
  removed: boolean;
  historyEnabled: boolean;
  historyPolicy?: string;
  dataSyncEnabled: boolean;
  compatibilityInfo: FlowSinkCompatibilityInfo;
  lastSyncedDataAt: number;
  lastSyncedHistoryTimestamp: number;
  nextDataSyncAfter: number;
  syncError?: string;
  analyticsName: string;
  cubeName: string;
  blockId: number;
  blockDescription: string;
  blockApiAddress: string;
  blockVersion?: string;
  blockApiVersion?: string;
  blockHostDeviceType?: string;
}

export interface FlowSinkIntegrationDetails {
  id: number;
  externalId: number;
  name: string;
  sequenceNumber: string;
  outputValueType: string;
  dataSyncEnabled: boolean;
  lastSyncedDataAt: number;
  lastSyncedHistoryTimestamp: number;
  syncError?: string;
}

export interface FlowWebhook {
  id: number;
  externalWidgetId: number;
  name?: string;
  lastValueTimestampMs?: number;
  lastValue?: number;
  lastMessage?: number;
  parkingSection?: IdLabel;
  block: IdLabel;
  analytics: IdLabel;
}

export interface FrontendVersion {
  version?: string;
  buildTimestamp?: number;
}

export interface GenericConfig {
  name: string;
  rootConfigPrefix: string;
  propertiesHierarchy: GenericConfigSectionOrProperty[];
  properties: GenericConfigPropertyMetadata[];
  data: any;
}

export interface GenericConfigMetadata {
  name: string;
  rootConfigPrefix: string;
}

export interface GenericConfigPropertyMetadata {
  fullKey: string;
  type: string;
  name: string;
  min?: number;
  max?: number;
  enumValues?: IdLabel[];
}

export interface GenericConfigSectionOrProperty {
  sectionName?: string;
  propertyFullKey?: string;
  properties?: GenericConfigSectionOrProperty[];
}

export enum GlobalPriv {
  itcView = 10,
  itcEdit = 11,
  itcDisconnect = 12,
  itcTerminal = 13,
  itcTerminalHistory = 14,
  itcSvgManage = 15,
  itcStateStats = 16,
  itcEventAddComment = 20,
  itcEventEditComment = 21,
  itcEventsView = 22,
  itcNoteAdd = 30,
  itcServiceRequestManage = 40,
  itcServiceRequestRealize = 41,
  itcCoordChart = 50,
  trafficLoadChart = 51,
  itcGroupManage = 60,
  procedureViewAndExecute = 100,
  procedureEdit = 101,
  ppPlanView = 110,
  ppPlanEdit = 111,
  busView = 200,
  busEdit = 201,
  busPriorityStats = 202,
  busPriorityConfig = 203,
  busPriorityTracesView = 204,
  busPriorityLiveDataView = 205,
  spvView = 210,
  spvEdit = 211,
  spvTrackWithoutPriority = 212,
  priorityRadioView = 220,
  vehiclePosLog = 221,
  trafficCamView = 300,
  trafficCamEdit = 301,
  trafficCamSnapshotView = 302,
  chargingStationView = 310,
  chargingStationEdit = 311,
  meteoStationView = 320,
  meteoStationEdit = 321,
  flowView = 330,
  flowEdit = 331,
  flowCartogramView = 332,
  flowCartogramEdit = 333,
  flowOdMatrixView = 334,
  parkingSystemView = 340,
  parkingSystemEdit = 341,
  parkingSystemCalibrate = 342,
  outputDeviceView = 350,
  outputDeviceManage = 351,
  trafficEventView = 360,
  measurePointView = 900,
  actionLogView = 1000,
  smsNotificationConfig = 1001,
  calendarDayEdit = 1002,
  systemConfigManage = 1003,
  tenantUsersManage = 1004,
  langTranslationsManage = 1005,
  filesView = 1100,
  filesAdd = 1101,
  filesRemove = 1102,
  externalApiParking = 1200,
  externalApiVms = 1201,
  externalApiAvdDevices = 1202,
  externalApiMeteo = 1203,
  externalApiPriority = 1204,
  externalApiFlowWebhook = 1205,
  externalApiCams = 1206,
  browserPresentationMode = 2000,
}

export const globalPrivDescription = {
  [GlobalPriv.itcView]: 'Przeglądanie sterowników',
  [GlobalPriv.itcEdit]: 'Edycja sterowników',
  [GlobalPriv.itcDisconnect]: 'Odłączenie sterowników od systemu',
  [GlobalPriv.itcTerminal]: 'Dostęp do terminala sterownika',
  [GlobalPriv.itcTerminalHistory]: 'Przeglądanie historii sesji terminala sterownika',
  [GlobalPriv.itcSvgManage]: 'Zarządzanie rysunkami skrzyżowań',
  [GlobalPriv.itcStateStats]: 'Przeglądanie statystyki stanów sterowników',
  [GlobalPriv.itcEventAddComment]: 'Dodawanie uwag do logów sterowników',
  [GlobalPriv.itcEventEditComment]: 'Edycja całej zawartości uwag logów sterowników',
  [GlobalPriv.itcEventsView]: 'Przeglądanie logów sterowników, recordmem',
  [GlobalPriv.itcNoteAdd]: 'Dodawanie notatek sterowników',
  [GlobalPriv.itcServiceRequestManage]: 'Zarządzanie zgłoszeniami serwisowymi skrzyżowań (dodawanie, edycja)',
  [GlobalPriv.itcServiceRequestRealize]: 'Realizacja zgłoszeń serwisowych skrzyżowań',
  [GlobalPriv.itcCoordChart]: 'Przeglądanie i zarządzanie wykresami koordynacji',
  [GlobalPriv.trafficLoadChart]: 'Przeglądanie i zarządzanie wykresami natężenia ruchu',
  [GlobalPriv.itcGroupManage]: 'Zarządzanie grupami sterowników',
  [GlobalPriv.procedureViewAndExecute]: 'Przeglądanie i uruchamianie procedur, sterowanie',
  [GlobalPriv.procedureEdit]: 'Edycja procedur',
  [GlobalPriv.ppPlanView]: 'Przeglądanie scenariuszy',
  [GlobalPriv.ppPlanEdit]: 'Edycja scenariuszy',
  [GlobalPriv.busView]: 'Przeglądanie pojazdów i stref detekcji transportu publicznego',
  [GlobalPriv.busEdit]: 'Zarządzanie strefami detekcji transportu publicznego',
  [GlobalPriv.busPriorityStats]: 'Przeglądanie statystyki priorytetów',
  [GlobalPriv.busPriorityConfig]: 'Konfiguracja priorytetów',
  [GlobalPriv.busPriorityTracesView]: 'Przeglądanie śladów systemu priorytetu',
  [GlobalPriv.busPriorityLiveDataView]: 'Przeglądanie danych predykcji na żywo',
  [GlobalPriv.spvView]: 'Przeglądanie pojazdów specjalnych',
  [GlobalPriv.spvEdit]: 'Edycja pojazdów specjalnych',
  [GlobalPriv.spvTrackWithoutPriority]: 'Przeglądanie pozycji pojazdów specjalnych, które nie żądają priorytetu',
  [GlobalPriv.priorityRadioView]: 'Przeglądanie radii systemu priorytetu',
  [GlobalPriv.vehiclePosLog]: 'Przeglądanie historii pozycji pojazdów',
  [GlobalPriv.trafficCamView]: 'Przeglądanie kamer',
  [GlobalPriv.trafficCamEdit]: 'Edycja kamer',
  [GlobalPriv.trafficCamSnapshotView]: 'Przeglądanie zdjęć kamer',
  [GlobalPriv.chargingStationView]: 'Przeglądanie stacji ładowania',
  [GlobalPriv.chargingStationEdit]: 'Edycja stacji ładowania',
  [GlobalPriv.meteoStationView]: 'Przeglądanie stacji meteo',
  [GlobalPriv.meteoStationEdit]: 'Edycja stacji meteo',
  [GlobalPriv.flowView]: 'Przeglądanie urządzeń FLOW',
  [GlobalPriv.flowEdit]: 'Zarządzanie urządzeniami FLOW',
  [GlobalPriv.flowCartogramView]: 'Przeglądanie kartogramów',
  [GlobalPriv.flowCartogramEdit]: 'Edycja kartogramów',
  [GlobalPriv.flowOdMatrixView]: 'Przeglądanie struktury kierunkowej',
  [GlobalPriv.parkingSystemView]: 'System parkingowy - przeglądanie',
  [GlobalPriv.parkingSystemEdit]: 'System parkingowy - zarządzanie',
  [GlobalPriv.parkingSystemCalibrate]: 'System parkingowy - kalibracja',
  [GlobalPriv.outputDeviceView]: 'Przeglądanie urządzeń wyjściowych',
  [GlobalPriv.outputDeviceManage]: 'Zarządzanie urządzeniami wyjściowymi',
  [GlobalPriv.trafficEventView]: 'Przeglądanie zdarzeń ruchu drugowego',
  [GlobalPriv.measurePointView]: 'Przeglądanie punktów pomiaru ruchu',
  [GlobalPriv.actionLogView]: 'Przeglądanie audytu operacji w systemie',
  [GlobalPriv.smsNotificationConfig]: 'Konfiguracja powiadomień SMS',
  [GlobalPriv.calendarDayEdit]: 'Zarządzanie kalendarzem - dni wolne',
  [GlobalPriv.systemConfigManage]: 'Konfiguracja systemu',
  [GlobalPriv.tenantUsersManage]: 'Zarządzanie użytkownikami',
  [GlobalPriv.langTranslationsManage]: 'Zarządzanie tłumaczeniami',
  [GlobalPriv.filesView]: 'Przeglądanie plików, dokumentacji',
  [GlobalPriv.filesAdd]: 'Dodawanie plików, dokumentacji',
  [GlobalPriv.filesRemove]: 'Usuwanie plików, dokumentacji',
  [GlobalPriv.externalApiParking]: 'API zewnętrzne - parkingi',
  [GlobalPriv.externalApiVms]: 'API zewnętrzne - VMS',
  [GlobalPriv.externalApiAvdDevices]: 'API zewnętrzne - urządzenia AVD',
  [GlobalPriv.externalApiMeteo]: 'API zewnętrzne - stacje meteo',
  [GlobalPriv.externalApiPriority]: 'API zewnętrzne - system priorytetu',
  [GlobalPriv.externalApiFlowWebhook]: 'API zewnętrzne - webhook FLOW',
  [GlobalPriv.externalApiCams]: 'API zewnętrzne - kamery',
  [GlobalPriv.browserPresentationMode]: 'Dostęp do trybu prezentacyjnego',
};

export interface GpsCoords {
  lat: number;
  lng: number;
}

export interface HubConnectionMetadata {
  userAgent: string;
  userId: number;
  userName: string;
  impersonatingUserId?: number;
  impersonatingUserName?: string;
  oldInterface: boolean;
  connectedAt: number;
  remoteIp: string;
  userConnectionSessionId?: number;
  webClientMetadataReport?: WebClientMetadataReport;
  headers?: any;
}

export interface IdLabel {
  id: number;
  label: string;
  annotation?: string;
}

export interface ImpersonateUserRequest {
  impersonatedUserId: number;
}

export enum IntlKeyTranslationUpdateSource {
  Unknown = 0,
  ManualEdit = 1,
  ImportFromPoFile = 2,
  ImportFromJsonFile = 3,
  AutoTranslate = 4,
}

export interface Itc {
  id: number;
  tenant: IdLabel;
  state: ItcState;
  plan?: number;
  name: string;
  gpsLocation: GpsCoords;
  firmwareVersion: string;
  controllerType?: string;
  controllerTypeId: number;
  ip: string;
  itcConnPort: number;
  priorityState: number;
  scatsSiteId?: string;
  procedureState: ItcProcedureState;
  intersections: ItcDetailsIntersection[];
  holdGroupNr?: number;
  holdGroupRepeatDelay?: number;
  holdGroupMaxExtendTime?: number;
  currentErrors: string;
  currentErrorsList: ItcCurrentError[];
  hasCurrentNotes?: boolean;
  hasCurrentServiceRequests?: boolean;
  lastUpdate?: number;
  outdatedLastUpdate?: boolean;
  noLiveData?: boolean;
  simCardExpired?: boolean;
  blocked?: boolean;
  removed?: boolean;
}

export interface ItcBlockedInfo {
  isBlocked: boolean;
  blockedReason?: string;
  warnings?: string[];
}

export interface ItcChangedEventData {
  id: number;
  state: ItcState;
  procedureState: ItcProcedureState;
  priorityState?: number;
  currentErrors: string;
}

export interface ItcConnectorConfig {
  id: number;
  crsId: number;
  name?: string;
  ip: string;
  port: number;
  configFile: string;
}

export interface ItcConnectorStatus {
  id: number;
  crsId: number;
  name?: string;
  isRunning: boolean;
  isConnected: boolean;
}

export enum ItcCrsErrorState {
  unknownError = 0,
  ok = 1,
  error = 2,
  criticalError = 3,
  noPower = 6,
  manualControl = 7,
  turnOff = 8,
}

export const itcCrsErrorStateDescription = {
  [ItcCrsErrorState.unknownError]: 'Nieznany błąd',
  [ItcCrsErrorState.ok]: 'Praca prawidłowa',
  [ItcCrsErrorState.error]: 'Błąd',
  [ItcCrsErrorState.criticalError]: 'Błąd krytyczny',
  [ItcCrsErrorState.noPower]: 'Brak zasilania',
  [ItcCrsErrorState.manualControl]: 'Sterowanie ręczne',
  [ItcCrsErrorState.turnOff]: 'Wyłączony',
};

export enum ItcCrsMode {
  unknown = 0,
  color = 1,
  flash = 2,
  allRed = 3,
  startup = 4,
  colorManual = 9,
  flashManual = 10,
  disconnected = 11,
  dark = 15,
}

export const itcCrsModeDescription = {
  [ItcCrsMode.unknown]: 'Nieznany',
  [ItcCrsMode.color]: '3 kolory',
  [ItcCrsMode.flash]: 'Migowy',
  [ItcCrsMode.allRed]: 'Ogólno czerwony, sterowanie ręczne',
  [ItcCrsMode.startup]: 'Rozruch',
  [ItcCrsMode.colorManual]: '3 kolory, praca krokowa',
  [ItcCrsMode.flashManual]: 'Migowy, sterowanie ręczne',
  [ItcCrsMode.disconnected]: 'Odłączony od systemu',
  [ItcCrsMode.dark]: 'Wyciemniony',
};

export interface ItcCurrentError {
  category: ItcCurrentErrorCategory;
  isCritical: boolean;
  wh: number;
  prefix: string;
  description: string;
}

export enum ItcCurrentErrorCategory {
  Other = 0,
  Detector = 1,
  Lamp = 2,
}

export interface ItcDetails {
  id: number;
  tenant: IdLabel;
  state: ItcState;
  plan?: number;
  name: string;
  gpsLocation: GpsCoords;
  firmwareVersion: string;
  controllerType?: string;
  controllerTypeId: number;
  ip: string;
  itcConnPort: number;
  priorityState: number;
  scatsSiteId?: string;
  procedureState: ItcProcedureState;
  intersections: ItcDetailsIntersection[];
  holdGroupNr?: number;
  holdGroupRepeatDelay?: number;
  holdGroupMaxExtendTime?: number;
  currentErrors: string;
  currentErrorsList: ItcCurrentError[];
  hasCurrentNotes?: boolean;
  hasCurrentServiceRequests?: boolean;
  lastUpdate?: number;
  outdatedLastUpdate?: boolean;
  noLiveData?: boolean;
  simCardExpired?: boolean;
  blocked?: boolean;
  removed?: boolean;
  city?: IdLabel;
  description: string;
  location: string;
  projectName: string;
  caseteHardware: string;
  planStr: string;
  planSource: string;
  activeProcedure?: string;
  currentNotes: ItcNote[];
  cams: TrafficCam[];
  simCards: ItcSimCardManage[];
  holdGroupImplemented: boolean;
  svg?: ItcSvgInfo;
  blockedInfo: ItcBlockedInfo;
  isSshAvailable: boolean;
  webInterfaceUrl?: string;
  powerVoltage?: number;
  temperature?: number;
  isMaestro: boolean;
}

export interface ItcDetailsIntersection {
  powerOn: boolean;
  mode: ItcCrsMode;
  error: ItcCrsErrorState;
  workDet: boolean;
  workLocal: boolean;
}

export interface ItcDetectorName {
  crsId: number;
  detNr: number;
  detId: number;
  counter: number;
  name: string;
}

export interface ItcEvent {
  id: number;
  itcId: number;
  pushDateUnix: number;
  eventDateUnix: number;
  code: string;
  description: string;
  type: string;
  prefix: string;
  itc?: IdLabel;
  userComments?: string;
  userId?: number;
  itcNoteId?: number;
}

export interface ItcEventAddComment {
  id: number;
  comment: string;
}

export interface ItcEventsCriteria {
  itcIds?: number[];
  dateStart?: string;
  dateEnd?: string;
  description?: string;
  code?: string;
  prefix?: string;
  types?: string[];
  controllerTypeId?: number;
  userCommentPresent?: boolean;
  page?: number;
  pageSize?: number;
}

export interface ItcEventsResponse {
  totalCount: number;
  providedCount: number;
  items: ItcEvent[];
  filteredItcs?: IdLabel[];
}

export interface ItcForPriorityIoConfig {
  id: number;
  label: string;
  annotation?: string;
  ioMode: PriorityIoMode;
}

export interface ItcGroup {
  id: number;
  tenant?: IdLabel;
  name: string;
  itcs: ItcGroupItemEntry[];
}

export interface ItcGroupItemEntry {
  id: number;
  tenant: IdLabel;
  name: string;
  owner: string;
  removed: boolean;
}

export interface ItcGroupListEntry {
  id: number;
  label: string;
  annotation?: string;
  tenant: IdLabel;
}

export interface ItcManage {
  id: number;
  tenant?: IdLabel;
  type?: IdLabel;
  description: string;
  city?: IdLabel;
  ip: string;
  projectName: string;
  location: string;
  owner: string;
  ownerCustomName?: string;
  itcSshPort?: number;
  itcWebInterfacePort?: number;
  holdGroupImplemented: boolean;
  removed: boolean;
  radioIp?: string;
}

export interface ItcManageListEntry {
  id: number;
  label: string;
  annotation?: string;
  tenant: IdLabel;
  removed: boolean;
}

export interface ItcMapData {
  id: number;
  tenantId: number;
  state: ItcState;
  name: string;
  gpsLocation: GpsCoords;
  priorityState: number;
  procedureState: ItcProcedureState;
  intersections: ItcDetailsIntersection[];
  holdGroupNr?: number;
  hasCurrentNotes?: boolean;
  hasCurrentServiceRequests?: boolean;
  outdatedLastUpdate?: boolean;
  noLiveData?: boolean;
  simCardExpired?: boolean;
  isWebInterfaceAvailable?: boolean;
  isSshAvailable?: boolean;
}

export interface ItcNote {
  id: number;
  userId: number;
  userName: string;
  crs?: IdLabel;
  createdAt: number;
  updatedAt: number;
  periodStart?: number;
  periodEnd?: number;
  content: string;
  type: ItcNoteType;
}

export enum ItcNoteType {
  OnlyText = 1,
  PeriodVisibleOnTheMap = 2,
  ItcServicedPart = 98,
}

export const itcNoteTypeDescription = {
  [ItcNoteType.OnlyText]: 'Zwykła notatka',
  [ItcNoteType.PeriodVisibleOnTheMap]: 'Notatka okresowa widoczna na mapie',
  [ItcNoteType.ItcServicedPart]: 'Wpis rejestru wymienionych części',
};

export interface ItcPart {
  id: number;
  name: string;
  group?: string;
  removed: boolean;
  removedAt?: number;
}

export interface ItcPlan {
  itcTypeId: number;
  planNumber: number;
  commandNumber: number;
}

export enum ItcProcedureState {
  NotRunning = 0,
  ManualRunning = 1,
  AutoRunning = 2,
}

export const itcProcedureStateDescription = {
  [ItcProcedureState.NotRunning]: 'Brak uruchomionej procedury',
  [ItcProcedureState.ManualRunning]: 'Procedura uruchomiona ręcznie',
  [ItcProcedureState.AutoRunning]: 'Procedura uruchomiona automatycznie',
};

export interface ItcProgramChartExport {
  itcId: number;
  signals: ItcProgramChartExportSignal[];
}

export interface ItcProgramChartExportPeriod {
  signalValue: string;
  description: string;
  startWh: number;
  duration: number;
  color: string;
}

export interface ItcProgramChartExportSignal {
  signalNumber: number;
  name: string;
  periods: ItcProgramChartExportPeriod[];
}

export interface ItcRecordmem {
  id: number;
  itc: IdLabel;
  wh: number;
  whGet: number;
  reason: string;
}

export interface ItcRecordmemCriteria {
  itcIds?: number[];
  page?: number;
  pageSize?: number;
}

export interface ItcRecordmemListResponse {
  totalCount: number;
  providedCount: number;
  items: ItcRecordmem[];
}

export interface ItcResetSmsConfig {
  crsId: number;
  ip?: string;
  crsName: string;
  enabled: boolean;
  smsContent: string;
  phoneNr?: string;
  delayMinutes: number;
  retryAfterHours?: number;
  maxRetryCount?: number;
}

export interface ItcResetSmsLog {
  id: number;
  crsId: number;
  crsName: string;
  sentAt: number;
  mobilePhone: string;
  smsContent: string;
}

export interface ItcServiceRequest {
  id: number;
  crs: IdLabel;
  realizationDatePolicy: ItcServiceRequestRealizationDatePolicy;
  acceptTimeProfile?: IdLabel;
  repairTimeProfile?: IdLabel;
  title: string;
  details: string;
  status: ItcServiceRequestStatus;
  createdAt: number;
  closedAt?: number;
  deadlineDate?: number;
  reportedRealizedAt?: number;
  realizationComment?: string;
  reportedAcceptedAt?: number;
  acceptComment?: string;
  createdByUser: IdLabel;
  excludeFromStats: boolean;
  acceptMinutesTook?: number;
  repairMinutesTook?: number;
  acceptTimeAboveLimit?: boolean;
  repairTimeAboveLimit?: boolean;
  handlingUserGroup?: IdLabel;
}

export interface ItcServiceRequestAcceptCommand {
  id: number;
  reportedAcceptAt: number;
  repairTimeProfileId?: number;
  acceptComment?: string;
}

export interface ItcServiceRequestAddAttachmentCommand {
  id: number;
  fileIds: number[];
}

export interface ItcServiceRequestCommandWithComment {
  id: number;
  comment?: string;
}

export interface ItcServiceRequestComment {
  id: number;
  serviceRequestId: number;
  user: IdLabel;
  createdAt: number;
  comment: string;
}

export interface ItcServiceRequestCreateCommand {
  crsId?: number;
  realizationDatePolicy: ItcServiceRequestRealizationDatePolicy;
  acceptTimeProfileId?: number;
  repairTimeProfileId?: number;
  deadlineDate?: number;
  title?: string;
  details?: string;
  fileIds?: number[];
  handlingUserGroupId?: number;
}

export interface ItcServiceRequestDetails {
  request: ItcServiceRequest;
  events: ItcServiceRequestEvent[];
  comments: ItcServiceRequestComment[];
  attachments: FileMetadata[];
  servicedParts: ItcServicedPart[];
}

export interface ItcServiceRequestEvent {
  id: number;
  serviceRequestId: number;
  user: IdLabel;
  occuredAt: number;
  title: string;
  details: string;
  fileId?: number;
}

export enum ItcServiceRequestRealizationDatePolicy {
  profileTimes = 0,
  deadlineDate = 1,
}

export const itcServiceRequestRealizationDatePolicyDescription = {
  [ItcServiceRequestRealizationDatePolicy.profileTimes]: 'Profil czasu reakcji/naprawy',
  [ItcServiceRequestRealizationDatePolicy.deadlineDate]: 'Określona data realizacji',
};

export interface ItcServiceRequestRealizeCommand {
  id: number;
  reportedRealizedAt: number;
  repairTimeProfileId?: number;
  comment?: string;
}

export interface ItcServiceRequestServicedPartCommand {
  id: number;
  partId?: number;
  servicedAt: number;
  comment?: string;
}

export enum ItcServiceRequestStatus {
  open = 1,
  realized = 2,
  accepted = 3,
  canceled = 9,
}

export const itcServiceRequestStatusDescription = {
  [ItcServiceRequestStatus.open]: 'Otwarte',
  [ItcServiceRequestStatus.realized]: 'Zrealizowane',
  [ItcServiceRequestStatus.accepted]: 'Przyjęte do realizacji',
  [ItcServiceRequestStatus.canceled]: 'Anulowane',
};

export interface ItcServiceRequestTimeProfile {
  id: number;
  profileType: ItcServiceRequestTimeProfileType;
  name: string;
  maxTimeMinutes?: number;
  minutesStartValidity: number;
  minutesEndValidity: number;
  label: string;
  removed: boolean;
}

export enum ItcServiceRequestTimeProfileType {
  accept = 1,
  repair = 2,
}

export const itcServiceRequestTimeProfileTypeDescription = {
  [ItcServiceRequestTimeProfileType.accept]: 'Profil czasu przyjęcia zgłoszenia',
  [ItcServiceRequestTimeProfileType.repair]: 'Profil czasu realizacji zgłoszenia',
};

export interface ItcServiceRequestUpdateCommand {
  id: number;
  realizationDatePolicy: ItcServiceRequestRealizationDatePolicy;
  acceptTimeProfileId?: number;
  repairTimeProfileId?: number;
  deadlineDate?: number;
  title?: string;
  details?: string;
  handlingUserGroupId?: number;
}

export interface ItcServiceRequestsCriteria {
  itcIds?: number[];
  itcId?: number;
  dateFrom?: number;
  dateTo?: number;
  serviceRequestId?: number;
  statuses?: ItcServiceRequestStatus[];
  page?: number;
  pageSize?: number;
}

export interface ItcServiceRequestsResponse {
  totalCount: number;
  providedCount: number;
  items: ItcServiceRequest[];
  open: number;
  realized: number;
  realizedAboveMaxTime: number;
  acceptedAboveMaxTime: number;
  excludedFromStats: number;
}

export interface ItcServicedPart {
  id: number;
  part?: IdLabel;
  crs?: IdLabel;
  servicedAt: number;
  comment?: string;
  itcServiceRequestId?: number;
}

export enum ItcSignState {
  SignalNoChange = 0,
  SignalOff = 1,
  SignalOn = 2,
  SignalFlashOn = 3,
  SignalFlashOff = 4,
  SignalFixedOn = 5,
  SignalFixedOff = 6,
  SignalLocalOn = 7,
  SignalLocalOff = 8,
}

export const itcSignStateDescription = {
  [ItcSignState.SignalNoChange]: 'Brak wymuszenia',
  [ItcSignState.SignalOff]: 'Wyłącz',
  [ItcSignState.SignalOn]: 'Włącz',
  [ItcSignState.SignalFlashOn]: 'Żółte mig.',
  [ItcSignState.SignalFlashOff]: 'Żółte mig. wył.',
  [ItcSignState.SignalFixedOn]: 'Praca det.',
  [ItcSignState.SignalFixedOff]: 'Praca det. wył.',
  [ItcSignState.SignalLocalOn]: 'Praca lokalna',
  [ItcSignState.SignalLocalOff]: 'Praca lokalna wył.',
};

export interface ItcSignalGroupName {
  crsId: number;
  groupNr: number;
  name: string;
}

export interface ItcSimCardEntry {
  id: number;
  description?: string;
  contractStart?: number;
  contractEnd?: number;
  tenant?: IdLabel;
  itc?: IdLabel;
  city?: IdLabel;
}

export interface ItcSimCardManage {
  id: number;
  description?: string;
  contractStart?: number;
  contractEnd?: number;
  itc?: IdLabel;
}

export enum ItcState {
  unknownError = 0,
  ok = 1,
  error = 2,
  criticalError = 3,
  noAsr = 4,
  noModem = 5,
  noPower = 6,
  manualControl = 7,
  turnOff = 8,
  disconnected = 11,
  noLiveData = -100,
}

export const itcStateDescription = {
  [ItcState.unknownError]: 'Nieznany błąd',
  [ItcState.ok]: 'Praca prawidłowa',
  [ItcState.error]: 'Błąd',
  [ItcState.criticalError]: 'Błąd krytyczny',
  [ItcState.noAsr]: 'Błąd komunikacji ze sterownikiem',
  [ItcState.noModem]: 'Brak komunikacji z modemem',
  [ItcState.noPower]: 'Brak zasilania',
  [ItcState.manualControl]: 'Sterowanie ręczne',
  [ItcState.turnOff]: 'Wyłączony',
  [ItcState.disconnected]: 'Odłączony od systemu',
  [ItcState.noLiveData]: 'Brak danych na żywo',
};

export interface ItcStateReportCriteria {
  itcIds: number[];
  searchText?: string;
  itcGroup?: string;
  owner?: string;
  states?: ItcState[];
}

export interface ItcStats {
  stateStats: ItcStatsStateItem[];
  openServiceRequests: number;
}

export interface ItcStatsStateItem {
  state: ItcState;
  count: number;
}

export interface ItcSvgInfo {
  isSvgSet: boolean;
  svgId?: number;
  svgFilename?: string;
  itcId: number;
  itcName?: string;
}

export interface ItcSvgUpdate {
  itcId: number;
  filename: string;
  svg: string;
}

export interface ItcTerminalLogCriteria {
  searchText?: string;
  itcIds?: number[];
  page?: number;
  pageSize?: number;
}

export interface ItcTerminalLogEntry {
  id: number;
  userId: number;
  userName: string;
  itcId: number;
  itcName: string;
  connectTime: number;
  ip: string;
  port: number;
  sshUser: string;
  disconnectTime?: number;
}

export interface ItcTerminalLogPackets {
  packetsBase64: string[];
}

export interface ItcTerminalLogsResponse {
  totalCount: number;
  providedCount: number;
  items: ItcTerminalLogEntry[];
}

export interface ItcTypeDetails {
  id: number;
  name: string;
  altName?: string;
  label: string;
  signalFixedSupported: boolean;
  signalLocalSupported: boolean;
  logicalGroupSignalsSupported: boolean;
  disconnectFromZirSupported: boolean;
  lampLoadSupported: boolean;
  isMaestro: boolean;
}

export interface ItcUserAccessInfo {
  itc: IdLabel;
  user: IdLabel;
  accessSourceDescription: string;
}

export interface KeyTranslation {
  keyId: number;
  langId: number;
  key: string;
  context: string;
  translation: string;
  enHintTranslation?: string;
  updatedAt?: number;
  lastUpdateSource?: IntlKeyTranslationUpdateSource;
  lastUpdateUser?: IdLabel;
  sources?: string[];
}

export interface KeyTranslationImport {
  lang?: Lang;
  containsTranslations: boolean;
  keys: KeyTranslationImportItem[];
}

export interface KeyTranslationImportItem {
  sources?: string[];
  current: KeyTranslation;
  updated?: KeyTranslationUpdate;
}

export interface KeyTranslationImportRequest {
  fileContent: string;
  filename: string;
}

export interface KeyTranslationUpdate {
  keyId: number;
  langId: number;
  key: string;
  context: string;
  translation: string;
  updateSource: IntlKeyTranslationUpdateSource;
  sources?: string[];
}

export interface KeysUpdateStats {
  processed: number;
  updated: number;
  errors: string[];
}

export interface Lang {
  id: number;
  code: string;
  name: string;
  englishName: string;
  nativeName: string;
  published: boolean;
}

export interface LoginRequest {
  login: string;
  password: string;
}

export interface LoginRequestWithPassChange {
  login: string;
  password: string;
  newPassword: string;
  newPassword2: string;
}

export interface LoginResponse {
  success: boolean;
  requirePasswordChange: boolean;
}

export interface MapBounds {
  latMin: number;
  latMax: number;
  lngMin: number;
  lngMax: number;
}

export interface MapBusDelayToPriority {
  operation?: OperationKind;
  cityId?: number;
  crsId?: number;
  lineId?: number;
  lowPriorMinDelay: number;
  midPriorMinDelay: number;
  highPriorMinDelay: number;
  startMinute: number;
  endMinute: number;
}

export interface MapCriteriaWithBounds {
  objectKinds?: MapObjectKind[];
  lineNumber?: string;
  busDelayLevel?: BusDelayLevel;
  cityId?: number;
  spvKinds?: VehicleType[];
  mapZoomLevel?: number;
  mapBounds?: MapBounds;
}

export interface MapObject {
  id: number;
  kind: MapObjectKind;
  gpsLocation: GpsCoords;
  data: unknown;
  stateHash: number;
}

export enum MapObjectKind {
  itc = 10,
  bus = 20,
  busPrediction = 21,
  busZone = 30,
  spv = 40,
  spvZone = 50,
  trafficCam = 60,
  measurePoint = 70,
  parkingGateway = 80,
  parkingSection = 81,
  parkingSensor = 82,
  parkingAtkiDevice = 83,
  vms = 90,
  priorirtyRadio = 100,
  chargingStation = 110,
  meteoStation = 120,
  flowBlock = 130,
  flowAnalytics = 131,
  outputDevice = 140,
}

export const mapObjectKindDescription = {
  [MapObjectKind.itc]: 'Sterownik sygnalizacji',
  [MapObjectKind.bus]: 'Transport publiczny',
  [MapObjectKind.busPrediction]: 'Transport publiczny - predykcja',
  [MapObjectKind.busZone]: 'Strefa detekcji transportu publicznego',
  [MapObjectKind.spv]: 'Pojazd specjalny',
  [MapObjectKind.spvZone]: 'Strefa detekcji pojazdu specjalnego',
  [MapObjectKind.trafficCam]: 'Kamera',
  [MapObjectKind.measurePoint]: 'Punkt pomiaru ruchu',
  [MapObjectKind.parkingGateway]: 'Brama systemu parkingowego',
  [MapObjectKind.parkingSection]: 'Segment parkingu',
  [MapObjectKind.parkingSensor]: 'Czujnik miejsca parkingowego',
  [MapObjectKind.parkingAtkiDevice]: 'Urządzenie ATKI',
  [MapObjectKind.vms]: 'Tablica parkingowa',
  [MapObjectKind.priorirtyRadio]: 'Radio systemu priorytetu',
  [MapObjectKind.chargingStation]: 'Stacja ładowania',
  [MapObjectKind.meteoStation]: 'Stacja meteo',
  [MapObjectKind.flowBlock]: 'Urządzenie FLOW',
  [MapObjectKind.flowAnalytics]: 'Kamera FLOW',
  [MapObjectKind.outputDevice]: 'Urządzenie wyjściowe',
};

export interface MapObjects {
  mapBounds?: MapBounds;
  objects: MapObject[];
}

export interface MeasurePoint {
  id: number;
  gpsLocation: GpsCoords;
  name: string;
}

export interface MeasurePointDetector {
  id: number;
  pointId: number;
  detectorNr: number;
  name: string;
  view: string;
  traffic?: number;
  trafficUpdate: number;
}

export interface MenuSearchResult {
  searchText: string;
  tooGeneralSearchText: boolean;
  maxItemsLimitReached: boolean;
  items: MenuSearchResultItem[];
}

export interface MenuSearchResultItem {
  id: number;
  entityType: string;
  entityTypeDescription?: string;
  label: string;
  annotation?: string;
  tenant?: IdLabel;
  gpsLocation?: GpsCoords;
  mapObjectKind?: MapObjectKind;
  attributes?: any;
}

export interface MeteoParam {
  id: number;
  code: string;
  name?: string;
  unit?: string;
}

export interface MeteoParamValue {
  parameter: MeteoParam;
  wh: number;
  stringValue?: string;
  numericValue?: number;
  noValueReason?: MeteoParameterNoValueReason;
  errorCode?: number;
  errorMessage?: string;
}

export enum MeteoParameterNoValueReason {
  Error = 0,
  NotAggregated = 1,
  NotAvailable = 2,
}

export const meteoParameterNoValueReasonDescription = {
  [MeteoParameterNoValueReason.Error]: 'Błąd czujnika',
  [MeteoParameterNoValueReason.NotAggregated]: 'Brak agregacji danych z czujnika',
  [MeteoParameterNoValueReason.NotAvailable]: 'Wartość niedostępna',
};

export interface MeteoStation {
  id: number;
  tenant: IdLabel;
  externalId: string;
  name: string;
  gpsLocation: GpsCoords;
}

export interface ModemStatus {
  id: number;
  controllerId: number;
  itc?: IdLabel;
  lastMessage: number;
  cpuUsage: number;
  ramUsage: number;
  sdCardFreeMb: number;
  firmwareVersion: string;
  mac: string;
  projectName: string;
  isControllerCon: number;
  isConnected: number;
  ip: string;
  phoneNr?: string;
}

export interface NotificationItcServiceRequestConditions {
  operationCodes?: string[];
}

export interface NotificationItcStateChangeConditions {
  prevState?: ItcState[];
  nextState?: ItcState[];
  error?: string;
}

export interface NotificationParkingSystemLogConditions {
  categories?: ParkingSystemLogCategory[];
}

export interface NotificationRule {
  id: number;
  ruleScope: NotificationRuleScope;
  globalScopeLang?: Lang;
  ruleClass: NotificationRuleClass;
  userId?: number;
  itcs?: IdLabel[];
  detailedConditions: any;
  email: boolean;
  emailTopic: string;
  emailContent: string;
  sms: boolean;
  smsContent: string;
  app: boolean;
  appHeader: string;
  appContent: string;
}

export enum NotificationRuleClass {
  itcStateChange = 1,
  itcServiceRequest = 2,
  appNewVersion = 3,
  autoDisableFlowSync = 4,
  parkingSystemLog = 5,
  parkingMonthRawDataSnapshot = 6,
  trafficEvent = 7,
  bruteForceProtectionAlert = 90,
  appLogErrors = 99,
}

export const notificationRuleClassDescription = {
  [NotificationRuleClass.itcStateChange]: 'Zmiana stanu sterownika',
  [NotificationRuleClass.itcServiceRequest]: 'Zgłoszenie serwisowe skrzyżowania',
  [NotificationRuleClass.appNewVersion]: 'Nowa wersja aplikacji',
  [NotificationRuleClass.autoDisableFlowSync]: 'Automatyczne wyłączenie synchronizacji FLOW',
  [NotificationRuleClass.parkingSystemLog]: 'Zdarzenie systemu parkingowego',
  [NotificationRuleClass.parkingMonthRawDataSnapshot]: 'Miesięczny zrzut danych zajętości parkingów',
  [NotificationRuleClass.trafficEvent]: 'Zdarzenie ruchu drogowego',
  [NotificationRuleClass.bruteForceProtectionAlert]: 'Alert bezpieczeństwa - brute force protection',
  [NotificationRuleClass.appLogErrors]: 'Błędy w logach aplikacji',
};

export interface NotificationRuleDefaults {
  email: boolean;
  emailTopic: string;
  emailContent: string;
  sms: boolean;
  smsContent: string;
  app: boolean;
  appHeader: string;
  appContent: string;
}

export interface NotificationRuleMetadata {
  ruleClass: NotificationRuleClass;
  description: string;
  defaults: NotificationRuleDefaults;
}

export enum NotificationRuleScope {
  global = 1,
  user = 2,
}

export enum ObjectPrivType {
  itc = 1,
  trafficCam = 2,
  itcGroup = 3,
  chargingStation = 4,
  meteoStation = 5,
  flowBlock = 6,
  intlLang = 99,
}

export const objectPrivTypeDescription = {
  [ObjectPrivType.itc]: 'Sterowniki',
  [ObjectPrivType.trafficCam]: 'Kamery',
  [ObjectPrivType.itcGroup]: 'Grupy sterowników',
  [ObjectPrivType.chargingStation]: 'Stacje ładowania',
  [ObjectPrivType.meteoStation]: 'Stacje meteo',
  [ObjectPrivType.flowBlock]: 'Urządzenia FLOW',
  [ObjectPrivType.intlLang]: 'intlLang',
};

export enum OperationKind {
  Add = 1,
  Remove = 2,
}

export interface OutputDevice {
  id: number;
  tenant?: IdLabel;
  deviceType: OutputDeviceType;
  name: string;
  ip: string;
  port: number;
  zirControlDisabled: boolean;
  controlSourceType?: OutputDeviceControlSourceType;
  controlSourceEntityId?: number;
  controlSourceConfiguration: any;
}

export enum OutputDeviceControlSourceType {
  ParkingSectionOccupancy = 10,
}

export const outputDeviceControlSourceTypeDescription = {
  [OutputDeviceControlSourceType.ParkingSectionOccupancy]: 'Zajętość segmentu parkingowego',
};

export interface OutputDeviceDetails {
  id: number;
  name: string;
  deviceType: OutputDeviceType;
  tenant?: IdLabel;
  gpsLocation: GpsCoords;
  ip: string;
  port: number;
  zirControlDisabled: boolean;
  controlSourceType?: OutputDeviceControlSourceType;
  controlSourceEntityId?: number;
  controlSourceConfiguration?: string;
  controlSourceEntityDescription?: string;
  lastCommandDescription?: string;
  lastCommunication: number;
  syncError?: string;
}

export interface OutputDeviceListEntry {
  id: number;
  tenant?: IdLabel;
  deviceType: OutputDeviceType;
  name: string;
  zirControlDisabled: boolean;
}

export interface OutputDeviceMapData {
  id: number;
  tenantId: number;
  name: string;
  gpsLocation: GpsCoords;
  hasError?: boolean;
  controlDisabled?: boolean;
}

export interface OutputDeviceParkingSectionOccupancyConfiguration {
  occupiedSpacesThreshold: number;
  portNumberToSetAboveThreshold?: number;
  portNumberToSetBelowOrEqualThreshold?: number;
}

export interface OutputDeviceTestConnection {
  deviceType: OutputDeviceType;
  ip: string;
  port: number;
}

export interface OutputDeviceTestConnectionResult {
  deviceType: OutputDeviceType;
  ip: string;
  port: number;
  description?: string;
  hardwareDescription?: string;
  softwareVersion?: string;
  serialNumber?: string;
  success: boolean;
  errorCode?: number;
  errorMessage?: string;
}

export enum OutputDeviceType {
  Demo = 0,
  AtkiRedGreenLamp = 10,
}

export const outputDeviceTypeDescription = {
  [OutputDeviceType.Demo]: 'Demo',
  [OutputDeviceType.AtkiRedGreenLamp]: 'Sygnalizator czerwone/zielone ATKI',
};

export interface Parking {
  id: number;
  tenant?: IdLabel;
  name: string;
  gpsLocation: GpsCoords;
  removed: boolean;
  removedAt?: number;
}

export interface ParkingAreaLabel {
  parkingObjectKind: ParkingObjectKind;
  id: number;
  label: string;
}

export interface ParkingErrorStatsCriteria {
  scopeStart: number;
  scopeEnd: number;
  tenantId: number;
  concurrentDevicesWithErrorPercentageThreshold?: number;
}

export interface ParkingErrorStatsResponse {
  scopeStart: number;
  scopeEnd: number;
  totalErrorSeconds: number;
  errorFactor: number;
  vmsCount: number;
  parkingSensorCount: number;
  atkiDeviceCount: number;
  concurrentDevicesWithErrorPercentageThreshold?: number;
  minutesWithErrorDevicesAboveThreshold?: number;
  errorItems: ParkingErrorStatsResponseErrorItem[];
}

export interface ParkingErrorStatsResponseErrorItem {
  beginErrorLog: ParkingSystemLog;
  endErrorLog?: ParkingSystemLog;
  errorSecondsDuration: number;
  isExcluded: boolean;
}

export interface ParkingGateway {
  id: number;
  tenantId: number;
  system: ParkingSystemKind;
  externalId: string;
  gpsLocation: GpsCoords;
  receivedMessageCount: number;
  location: string;
  lastUpdate: number;
}

export interface ParkingHistoryOccupancy {
  label: string;
  dateStart: number;
  dateEnd: number;
  items: ParkingHistoryOccupancyItem[];
}

export interface ParkingHistoryOccupancyCriteria {
  objectKind: ParkingObjectKind;
  objectId: number;
  dateStart: number;
  dateEnd: number;
}

export interface ParkingHistoryOccupancyItem {
  start: number;
  end: number;
  noData: boolean;
  occupied?: number;
  total?: number;
}

export interface ParkingMapObjectsList {
  sections: ParkingSectionMapListEntry[];
  dynamicSigns: VmsMapData[];
  atkiDevices: AtkiDevice[];
}

export interface ParkingObjectId {
  id: number;
  kind: ParkingObjectKind;
}

export enum ParkingObjectKind {
  Sensor = 1,
  Section = 2,
  Parking = 3,
}

export const parkingObjectKindDescription = {
  [ParkingObjectKind.Sensor]: 'Czujnik miejsca parkingowego',
  [ParkingObjectKind.Section]: 'Segment parkingu',
  [ParkingObjectKind.Parking]: 'Parking',
};

export enum ParkingPlaceType {
  Ordinary = 0,
  ReservedForDisabled = 10,
  ChargingStand = 20,
  Private = 30,
}

export const parkingPlaceTypeDescription = {
  [ParkingPlaceType.Ordinary]: 'Zwykłe',
  [ParkingPlaceType.ReservedForDisabled]: 'Osoby niepełnosprawne',
  [ParkingPlaceType.ChargingStand]: 'Samochód elektryczny',
  [ParkingPlaceType.Private]: 'Prywatne',
};

export interface ParkingPlaceTypeStats {
  placeType: ParkingPlaceType;
  spacesOccupied: number;
  spacesTotal: number;
}

export interface ParkingRawDataExportCriteria {
  tenantId?: number;
  type: ParkingRawDataExportType;
  parkingSectionIds?: number[];
  dateStart: number;
  dateEnd: number;
}

export enum ParkingRawDataExportType {
  Occupancy = 1,
  VehiclesInOut = 2,
}

export const parkingRawDataExportTypeDescription = {
  [ParkingRawDataExportType.Occupancy]: 'Zajętość parkingu',
  [ParkingRawDataExportType.VehiclesInOut]: 'Wjazdy/wyjazdy pojazdów',
};

export interface ParkingSection {
  id: number;
  tenant?: IdLabel;
  system: ParkingSystemKind;
  externalId: string;
  name?: string;
  type?: string;
  atkiDeviceType?: AtkiDeviceType;
  gpsLocation: GpsCoords;
  spacesOccupied: number;
  spacesTotal: number;
  autoCalibrateSpacesOccupied?: number;
  lastOccupancyUpdate: number;
  disabled: boolean;
  disabledComment?: string;
  isCalibrationAvailable: boolean;
  removed: boolean;
  removedAt?: number;
  parking?: IdLabel;
}

export interface ParkingSectionDetails {
  id: number;
  tenant?: IdLabel;
  system: ParkingSystemKind;
  externalId: string;
  name?: string;
  type?: string;
  atkiDeviceType?: AtkiDeviceType;
  gpsLocation: GpsCoords;
  spacesOccupied: number;
  vehicleCount: number;
  spacesTotal: number;
  lastOccupancyUpdate: number;
  disabled: boolean;
  disabledComment?: string;
  isCalibrationAvailable: boolean;
  parking?: IdLabel;
  atkiDevices: IdLabel[];
  placeTypeStats?: ParkingPlaceTypeStats[];
}

export interface ParkingSectionDisableSchedule {
  parkingSectionId: number;
  name: string;
  scheduleEntries: ParkingSectionDisableScheduleEntry[];
}

export interface ParkingSectionDisableScheduleEntry {
  id: number;
  priorityOrder: number;
  conditions: PriorityScheduleConditions;
  markSectionAsDisabled: boolean;
  disabledComment?: string;
}

export interface ParkingSectionManage {
  id: number;
  tenant?: IdLabel;
  system: ParkingSystemKind;
  externalId: string;
  name?: string;
  type?: string;
  atkiDeviceType?: AtkiDeviceType;
  gpsLocation: GpsCoords;
  spacesOccupied: number;
  spacesTotal: number;
  autoCalibrateSpacesOccupied?: number;
  lastOccupancyUpdate: number;
  disabled: boolean;
  disabledComment?: string;
  isCalibrationAvailable: boolean;
  removed: boolean;
  removedAt?: number;
  parking?: IdLabel;
  atkiDevices: IdLabel[];
}

export interface ParkingSectionMapData {
  id: number;
  tenantId: number;
  system: ParkingSystemKind;
  gpsLocation: GpsCoords;
  available: number;
  disabled?: boolean;
}

export interface ParkingSectionMapListEntry {
  id: number;
  system: ParkingSystemKind;
  externalId: string;
  name?: string;
  type?: string;
  atkiDeviceType?: AtkiDeviceType;
  gpsLocation: GpsCoords;
  spacesOccupied: number;
  spacesTotal: number;
  available: number;
  disabled: boolean;
}

export interface ParkingSectionWebhookUrl {
  parkingSectionId: number;
  webhookUrlIn?: string;
  webhookUrlOut?: string;
  webhookUrlArea?: string;
  errorFeedback?: string;
}

export interface ParkingSensor {
  id: number;
  tenant?: IdLabel;
  system: ParkingSystemKind;
  placeType: ParkingPlaceType;
  externalId: string;
  name?: string;
  type?: string;
  gpsLocation: GpsCoords;
  errorState: ParkingSensorErrorState;
  occupancy: ParkingSensorOccupancy;
  hardwareVersion?: string;
  softwareVersion?: string;
  batteryLevel?: number;
  batteryVoltage?: number;
  temperature?: number;
  dataLossRatio?: number;
  errors?: string;
  lastOccupancyUpdate: number;
  signalStrength?: number;
  signalQuality?: number;
  parkingSections: IdLabel[];
}

export interface ParkingSensorConfig {
  id: number;
  system: ParkingSystemKind;
  name?: string;
  externalId?: string;
  placeType: ParkingPlaceType;
}

export enum ParkingSensorErrorState {
  NoErrors = 0,
  Down = 1,
  Unknown = 2,
  NoDevice = 3,
}

export const parkingSensorErrorStateDescription = {
  [ParkingSensorErrorState.NoErrors]: 'Brak błędów',
  [ParkingSensorErrorState.Down]: 'Nie działa',
  [ParkingSensorErrorState.Unknown]: 'Stan czujnika jest nieznany',
  [ParkingSensorErrorState.NoDevice]: 'Brak urządzenia',
};

export interface ParkingSensorMapData {
  id: number;
  tenantId: number;
  sensorId: string;
  gpsLocation: GpsCoords;
  placeType: ParkingPlaceType;
  occupancy: ParkingSensorOccupancy;
}

export enum ParkingSensorOccupancy {
  Available = 0,
  Occupied = 1,
  Unknown = -1,
}

export const parkingSensorOccupancyDescription = {
  [ParkingSensorOccupancy.Available]: 'Niezajęte',
  [ParkingSensorOccupancy.Occupied]: 'Zajęte',
  [ParkingSensorOccupancy.Unknown]: 'Nieznane',
};

export interface ParkingStatistics {
  dateStart: number;
  dateEnd: number;
  items: ParkingStatisticsItem[];
}

export enum ParkingStatisticsAggregationLevel {
  QuarterHour = 10,
  Hour = 20,
  Day = 30,
  Week = 40,
  Month = 50,
  Year = 60,
}

export const parkingStatisticsAggregationLevelDescription = {
  [ParkingStatisticsAggregationLevel.QuarterHour]: '15 minut',
  [ParkingStatisticsAggregationLevel.Hour]: 'Godzina',
  [ParkingStatisticsAggregationLevel.Day]: 'Dzień',
  [ParkingStatisticsAggregationLevel.Week]: 'Tydzień',
  [ParkingStatisticsAggregationLevel.Month]: 'Miesiąc',
  [ParkingStatisticsAggregationLevel.Year]: 'Rok',
};

export interface ParkingStatisticsCriteria {
  dateStart: number;
  dateEnd: number;
  aggregationLevel: ParkingStatisticsAggregationLevel;
  objectIds: ParkingObjectId[];
}

export interface ParkingStatisticsItem {
  timestamp: number;
  objectId: ParkingObjectId;
  objectLabel: string;
  occupancyLevel: number;
}

export interface ParkingStats {
  segmentCount: number;
  sensorCount: number;
  sensorStates: ParkingStatsSensorItem[];
  atkiDevicesCount: number;
  atkiDeviceStates: ParkingStatsAtkiDeviceItem[];
}

export interface ParkingStatsAtkiDeviceItem {
  status: AtkiDeviceStatus;
  count: number;
}

export interface ParkingStatsSensorItem {
  errorState: ParkingSensorErrorState;
  count: number;
}

export enum ParkingSystemKind {
  Demo = 0,
  Urbiotica = 1,
  Atki = 2,
  FlowGate = 3,
  FlowArea = 4,
}

export const parkingSystemKindDescription = {
  [ParkingSystemKind.Demo]: 'Demo',
  [ParkingSystemKind.Urbiotica]: 'Urbiotica',
  [ParkingSystemKind.Atki]: 'Atki',
  [ParkingSystemKind.FlowGate]: 'FLOW Gate',
  [ParkingSystemKind.FlowArea]: 'FLOW Area',
};

export interface ParkingSystemLog {
  id: number;
  timestamp: number;
  category: ParkingSystemLogCategory;
  description: string;
  comment?: string;
  excludeFromServiceLevelCalculation?: boolean;
  section?: IdLabel;
  sensor?: IdLabel;
  atkiDevice?: IdLabel;
  vms?: IdLabel;
  user?: IdLabel;
}

export enum ParkingSystemLogCategory {
  Calibration = 10,
  AtkiDeviceMessageProcessingWarning = 100,
  AtkiRawIncomingMessageError = 101,
  AtkiProcessingMessageError = 102,
  AtkiDeviceStatusWarning = 103,
  AtkiDeviceStatusError = 104,
  AtkiDeviceStatusOk = 105,
  FlowWebhookProcessingWarning = 150,
  UrbioticaSyncError = 200,
  UrbioticaAmqpMessageProcessingError = 201,
  UrbioticaSensorDown = 202,
  UrbioticaSensorUp = 203,
  VmsSyncError = 300,
  VmsManualControl = 301,
  VmsSyncOk = 302,
  VmsPoorConnectionQuality = 310,
  VmsAverageConnectionQuality = 311,
  VmsConnectionPenaltyApplied = 320,
}

export const parkingSystemLogCategoryDescription = {
  [ParkingSystemLogCategory.Calibration]: 'Kalibracja',
  [ParkingSystemLogCategory.AtkiDeviceMessageProcessingWarning]: 'ATKI: ostrzeżenie przetwarzania komunikatu z urządzenia',
  [ParkingSystemLogCategory.AtkiRawIncomingMessageError]: 'ATKI: błąd przyjęcia komunikatu z urządzenia',
  [ParkingSystemLogCategory.AtkiProcessingMessageError]: 'ATKI: błąd przetworzenia komunikatu z urządzenia',
  [ParkingSystemLogCategory.AtkiDeviceStatusWarning]: 'ATKI: status z ostrzeżeniem',
  [ParkingSystemLogCategory.AtkiDeviceStatusError]: 'ATKI: status z błędem',
  [ParkingSystemLogCategory.AtkiDeviceStatusOk]: 'ATKI: status prawidłowy',
  [ParkingSystemLogCategory.FlowWebhookProcessingWarning]: 'FLOW: ostrzeżenia przetwarzania komunikatu webhook',
  [ParkingSystemLogCategory.UrbioticaSyncError]: 'Urbiotica: błąd synchronizacji obiektów',
  [ParkingSystemLogCategory.UrbioticaAmqpMessageProcessingError]: 'Urbiotica: błąd przetworzenia komunikatu',
  [ParkingSystemLogCategory.UrbioticaSensorDown]: 'Urbiotica: czujnik nie działa',
  [ParkingSystemLogCategory.UrbioticaSensorUp]: 'Urbiotica: czujnik działa prawidłowo',
  [ParkingSystemLogCategory.VmsSyncError]: 'VMS: błąd synchronizacji',
  [ParkingSystemLogCategory.VmsManualControl]: 'VMS: ręczna kontrola',
  [ParkingSystemLogCategory.VmsSyncOk]: 'VMS: praca prawidłowa',
  [ParkingSystemLogCategory.VmsPoorConnectionQuality]: 'VMS: słaba jakość połączenia',
  [ParkingSystemLogCategory.VmsAverageConnectionQuality]: 'VMS: przeciętna jakość połączenia',
  [ParkingSystemLogCategory.VmsConnectionPenaltyApplied]: 'VMS: karencja komunikacji',
};

export interface ParkingSystemLogComment {
  id: number;
  comment?: string;
  excludeFromServiceLevelCalculation: boolean;
}

export interface ParkingSystemLogCriteria {
  dateStart?: number;
  dateEnd?: number;
  categories?: ParkingSystemLogCategory[];
  sectionIds?: number[];
  atkiDeviceIds?: number[];
  vmsIds?: number[];
  description?: string;
  page?: number;
  pageSize?: number;
}

export interface ParkingSystemLogResponse {
  totalCount: number;
  providedCount: number;
  items: ParkingSystemLog[];
}

export interface PriorityBusLineConfig {
  busLineId: number;
  busLineName: string;
  systemName?: string;
  priorityEnabled: boolean;
}

export interface PriorityCrossingConfig {
  crsId: number;
  crsName: string;
  crsAnnotation?: string;
  busPriorityEnabled: boolean;
  spvPriorityEnabled: boolean;
  timeoutAllRed: number;
  timeoutGreen: number;
  heartbeatIO: number;
  ioMode: PriorityIoMode;
}

export interface PriorityCrossingIoConfig {
  crsId: number;
  ioNr: number;
  priorityEnabled: boolean;
}

export enum PriorityIoMode {
  InputStep10 = 0,
  InputStep5 = 10,
  InputStep1 = 20,
}

export const priorityIoModeDescription = {
  [PriorityIoMode.InputStep10]: '10-wejściowy',
  [PriorityIoMode.InputStep5]: '5-wejściowy',
  [PriorityIoMode.InputStep1]: '1-wejściowy',
};

export interface PriorityRadio {
  id: number;
  name: string;
  gpsLocation: GpsCoords;
  radioIp: string;
  framesReceivedToday?: number;
  lastMessage?: number;
  lastUpdate?: number;
  connected?: boolean;
  outdatedLastMessage?: boolean;
}

export interface PriorityScheduleConditions {
  dateFrom?: number;
  dateTo?: number;
  dayOfWeeks?: DayOfWeek[];
  monthNumber?: number;
  dayMinuteFrom?: number;
  dayMinuteTo?: number;
}

export interface PrioritySpvConfig {
  spvId: number;
  vehicleType: VehicleType;
  description: string;
  plate: string;
  priorityEnabled: boolean;
}

export interface Procedure {
  id: number;
  name: string;
  group: string;
  active: ProcedureActiveStatus;
  autoRunStatus: ProcedureAutoRunStatus;
}

export enum ProcedureActiveStatus {
  Off = 0,
  On = 1,
  OffWithError = 2,
  OnWithError = 3,
  OperationPending = 256,
}

export enum ProcedureAutoRunStatus {
  SuggestedOff = 0,
  SuggestedOn = 1,
  On = 2,
}

export interface ProcedureBinding {
  id: number;
  crsId: number;
  crsName: string;
  plan?: number;
  lightMode?: number;
  cmd?: string;
  criticalForExecution: boolean;
}

export interface ProcedureCanStartResponse {
  canStart: boolean;
  reason?: string;
}

export interface ProcedureCriteria {
  procedureId?: number;
  procedureIds?: number[];
  itcId?: number[];
  name?: string;
  group?: string;
}

export interface ProcedureDetails {
  id: number;
  procedure: Procedure;
  bindings: ProcedureBinding[];
}

export interface PtzPreset {
  id: string;
  name: string;
}

export interface RecoverPasswordCompleteCommand {
  login: string;
  email: string;
  token: string;
  newPassword: string;
  newPassword2: string;
}

export interface RecoverPasswordStartCommand {
  login: string;
  email: string;
}

export interface RequestInfo {
  requestId: string;
  startedAtUtc: string;
  elapsedMs: number;
  url: string;
  method: string;
  clientIp?: string;
  userId?: number;
  userName?: string;
}

export enum ScenarioProcedureSpecialCodes {
  TooLowTraffic = 0,
  NoData = -999,
  ScenarioOff = -2,
  InactiveScenario = -1,
}

export const scenarioProcedureSpecialCodesDescription = {
  [ScenarioProcedureSpecialCodes.TooLowTraffic]: 'Zbyt małe natężenie ruchu',
  [ScenarioProcedureSpecialCodes.NoData]: 'Brak danych',
  [ScenarioProcedureSpecialCodes.ScenarioOff]: 'Scenariusz wyłączony',
  [ScenarioProcedureSpecialCodes.InactiveScenario]: 'Scenariusz nieaktywny (poza godzinami)',
};

export enum SideMenuEdgeTriggerBehaviour {
  ActivateOnStrictEdge = 0,
  ActivateOnEdgeMargin = 10,
  DisableEdgeActivation = 20,
}

export const sideMenuEdgeTriggerBehaviourDescription = {
  [SideMenuEdgeTriggerBehaviour.ActivateOnStrictEdge]: 'Aktywuj dotknięciem kursora lewej krawędzi',
  [SideMenuEdgeTriggerBehaviour.ActivateOnEdgeMargin]: 'Aktywuj po najechaniu kursorem w pobliżu lewej krawędzi',
  [SideMenuEdgeTriggerBehaviour.DisableEdgeActivation]: 'Wyłącz aktywację krawędzią',
};

export interface SmsLog {
  id: number;
  sentAt: number;
  recipient: string;
  matchedUser?: string;
  content: string;
}

export interface SmsSendResult {
  success: boolean;
  recipient: string;
  sentMessage: string;
  serviceResponse?: string;
  errorFeedback?: string;
}

export interface SmsSendTestCommand {
  recipient: string;
  content: string;
}

export enum SpVehOnCrsTravelLogStatus {
  EndPriorityAllRed = 0,
  StartPriorityAllRed = 1,
  EndPrioritySignleExitForceGreen = 2,
  StartPrioritySignleExitForceGreen = 3,
  EndPriorityWithExistingOtherVehicleOnCrossing = 10,
  StartPriorityWithAlreadyCrossingPriorityActive = 11,
}

export const spVehOnCrsTravelLogStatusDescription = {
  [SpVehOnCrsTravelLogStatus.EndPriorityAllRed]: 'Koniec priorytetu na skrzyżowaniu dla pojazdu specjalnego (ALL RED)',
  [SpVehOnCrsTravelLogStatus.StartPriorityAllRed]: 'Początek priorytetu na skrzyżowaniu dla pojazdu specjalnego (ALL RED)',
  [SpVehOnCrsTravelLogStatus.EndPrioritySignleExitForceGreen]: 'Koniec priorytetu na jednym wlocie skrzyżowania dla pojazdu specjalnego (FORCE GREEN)',
  [SpVehOnCrsTravelLogStatus.StartPrioritySignleExitForceGreen]: 'Początek priorytetu na jednym wlocie skrzyżowania dla pojazdu specjalnego (FORCE GREEN)',
  [SpVehOnCrsTravelLogStatus.EndPriorityWithExistingOtherVehicleOnCrossing]: 'Koniec priorytetu na skrzyżowaniu dla pojazdu specjalnego (na skrzyżowaniu jest jeszcze inny pojazd)',
  [SpVehOnCrsTravelLogStatus.StartPriorityWithAlreadyCrossingPriorityActive]: 'Początek priorytetu na skrzyżowaniu dla pojazdu specjalnego (ALL RED) - na skrzyżowaniu już aktywny priorytet ALL RED',
};

export interface Spv {
  id: number;
  gpsLocation: GpsCoords;
  kind: VehicleType;
  lastUpdate: number;
  requiresPriority: boolean;
  description: string;
  connectionLost?: boolean;
}

export interface SpvCriteria {
  spvId?: number;
  requiresPriority?: boolean;
  lastUpdateTime?: number;
}

export interface SpvZone {
  id: number;
  kind: ZoneKind;
  crsId: number;
  points: GpsCoords[];
  pointsHash: number;
  active: number;
  ioNr: number;
  blockingZone: boolean;
  scatsActionEnter?: string;
  scatsActionExit?: string;
}

export interface SqlStats {
  items: SqlStatsItem[];
  asyncItems: SqlStatsItem[];
}

export interface SqlStatsItem {
  sql: string;
  todayCount: number;
  todayPerHourCount: number;
  todayDurationMs: number;
  todayAvgDurationMs: number;
  totalCount: number;
  totalPerHourCount: number;
  totalDurationMs: number;
  totalAvgDurationMs: number;
}

export interface SystemConfigUpdate {
  rootConfigPrefix: string;
  tenantId?: number;
  data: any;
}

export interface SystemMetricMetadata {
  id: number;
  scope: string;
  name: string;
  attributes?: string;
  description?: string;
  unit?: string;
  isMonotonic: boolean;
  maxValueHint?: number;
}

export interface SystemMetricReport {
  dateFrom: number;
  dateTo: number;
  metrics: SystemMetricReportItem[];
}

export interface SystemMetricReportCriteria {
  dateFrom?: number;
  dateTo?: number;
  metadataIds?: number[];
}

export interface SystemMetricReportItem {
  metadata: SystemMetricMetadata;
  values: SystemMetricReportValueItem[];
}

export interface SystemMetricReportValueItem {
  wh: number;
  value: number;
  delta?: number;
  deltaWhMs?: number;
}

export interface SystemMetricRequestStatsCriteria {
  dateFrom: number;
  dateTo: number;
  requestPathIds?: number[];
}

export interface SystemMetricRequestStatsReport {
  dateFrom: number;
  dateTo: number;
  itemDurationSeconds: number;
  items: SystemMetricRequestStatsReportItem[];
  paths: IdLabel[];
}

export interface SystemMetricRequestStatsReportItem {
  whStart: number;
  whEnd: number;
  pathId: number;
  count: number;
  avgDurationMs: number;
  minDurationMs: number;
  maxDurationMs: number;
}

export interface Tenant {
  id: number;
  name: string;
  customZirHostingAddress?: string;
  lang?: Lang;
  temporary: boolean;
}

export interface TenantMassiveChangeCommand {
  objectType: string;
  objectIds: number[];
  newTenantId: number;
}

export interface TrafficCam {
  id: number;
  tenant?: IdLabel;
  name?: string;
  url?: string;
  videoStreamUrlType: VideoStreamUrlType;
  videoStreamUrl?: string;
  videoStreamCodec?: string;
  snapshotAutoCollect: boolean;
  snapshotAutoCollectMinutesInterval: number;
  gpsLocation: GpsCoords;
  itc?: IdLabel;
  region?: string;
  roadName?: string;
  roadLocationMark?: string;
  direction?: string;
  apiType?: CamApiType;
  apiUser?: string;
  apiPassword?: string;
  apiChannel?: string;
  lastSnapshotId?: number;
  lastValidSnapshotId?: number;
}

export interface TrafficCamMapData {
  id: number;
  tenantId: number;
  name?: string;
  url?: string;
  hasVideoStream?: boolean;
  gpsLocation: GpsCoords;
  itc?: IdLabel;
}

export interface TrafficCamStreamSessionCriteria {
  dateFrom?: number;
  dateTo?: number;
  userId?: number;
  cameraId?: number;
}

export interface TrafficCamStreamSessionEntry {
  id: number;
  cam: TrafficCam;
  user: IdLabel;
  clientSessionId: number;
  whStart: number;
  whEnd: number;
}

export interface TrafficCamUpdateStreamSession {
  camId: number;
  clientSessionId: number;
}

export interface TrafficCorridor {
  id: number;
  tenant?: IdLabel;
  enable: boolean;
  ignoreDetectors: boolean;
  enableInWeekdays: number;
  enableInHolidays: boolean;
  name: string;
  minPlanUp: number;
  minPlanDown: number;
  detectorFunction: string;
  timeBack: number;
  startInSystem: number;
  stopInSystem: number;
  lastSelectedProcedure?: Procedure;
  lastSelectedProcedureId?: number;
  lastSelectedWh: number;
  detectors: TrafficCorridorDetector[];
  switchTable: TrafficCorridorSwitchTable[];
}

export interface TrafficCorridorChangeLog {
  date: string;
  count: number;
  scenario: TrafficCorridorListEntry;
  entries: TrafficCorridorChangeLogEntry[];
}

export interface TrafficCorridorChangeLogEntry {
  description: string;
  whSource: number;
  whStart: number;
  whEnd: number;
  duration: number;
  detVal?: number;
  mainDetId?: number;
  mainDetName?: string;
  mainDetCrsName?: string;
  mainDetGroupId?: number;
  procedure?: Procedure;
  procedureId: number;
}

export interface TrafficCorridorDetector {
  detectorId: number;
  detectorName: string;
  isMeasStation: boolean;
  sumGroup?: number;
  crsId?: number;
  crsName?: string;
  description: string;
}

export interface TrafficCorridorDetectorStatsEntry {
  detectorId: number;
  isMeasStation: boolean;
  wh: number;
  value: number;
}

export interface TrafficCorridorDetectorStatsRequest {
  detectorId: number;
  isMeasStation: boolean;
  timeBack: number;
}

export interface TrafficCorridorListEntry {
  id: number;
  enable: boolean;
  enableWeekdays: number;
  enableInHolidays: boolean;
  ignoreDetectors: boolean;
  name: string;
  detectorFunction: string;
  timeBack: number;
  startInSystem: number;
  stopInSystem: number;
  lastSelectedProcedure?: Procedure;
  lastSelectedProcedureId?: number;
  lastSelectedWh: number;
  lastSelectedDetVal: number;
  lastSelectDescription: string;
}

export interface TrafficCorridorSwitchTable {
  id: number;
  procedure: Procedure;
  switchUp: number;
  switchDown: number;
}

export interface TrafficEvent {
  id: number;
  externalId?: string;
  description: string;
  eventTimestamp: number;
  location?: string;
  vehicleType?: string;
  licensePlateNumber?: string;
  flowAnalyticsName?: string;
  flowBlockDescription?: string;
  preventAttachmentRemoval: boolean;
  attachments: TrafficEventAttachment[];
}

export interface TrafficEventAttachment {
  id: number;
  index: number;
  kind: TrafficEventAttachmentKind;
  storedAt: number;
  removedAt?: number;
  extension: string;
}

export enum TrafficEventAttachmentKind {
  Video = 1,
  Image = 2,
}

export const trafficEventAttachmentKindDescription = {
  [TrafficEventAttachmentKind.Video]: 'Wideo',
  [TrafficEventAttachmentKind.Image]: 'Zdjęcie',
};

export interface TrafficEventAttachmentPreventRemoveStatusUpdatedEvent {
  occuredAt: string;
  code: string;
  trafficEventId: number;
  preventAttachmentRemoval: boolean;
}

export interface TrafficEventsCriteria {
  dateStart?: number;
  dateEnd?: number;
  description?: string;
  flowBlockId?: number;
  flowAnalyticsId?: number;
  page?: number;
  pageSize?: number;
}

export interface TrafficEventsPagedResult {
  totalCount: number;
  providedCount: number;
  items: TrafficEvent[];
}

export interface TrafficLoadCounter {
  id: string;
  source: TrafficLoadCounterKind;
  itcDetectorId?: number;
  flowSinkId?: number;
  flowCategoryId?: number;
  label: string;
  analyticExpression: string;
}

export enum TrafficLoadCounterKind {
  ItcDetector = 1,
  Flow = 2,
}

export interface TrafficLoadCounterValue {
  itcDetId?: number;
  flowSinkId?: number;
  flowCategoryId?: number;
  wh: number;
  val: number;
}

export interface TrafficLoadCriteria {
  counters: TrafficLoadCounter[];
  dateStart: number;
  dateEnd: number;
}

export interface TrafficLoadExcelExport {
  title: string;
  detectors: TrafficLoadExcelExportDetector[];
  rows: any[];
}

export interface TrafficLoadExcelExportDetector {
  id: string;
  label: string;
}

export interface TrafficLoadLocation {
  id: string;
  itcId?: number;
  flowBlockId?: number;
  name: string;
  annotation?: string;
}

export interface TrafficLoadNewPreset {
  preset: TrafficLoadPreset;
  entries: TrafficLoadPresetEntry[];
}

export interface TrafficLoadPreset {
  id: number;
  crs?: IdLabel;
  flowBlock?: IdLabel;
  name: string;
}

export interface TrafficLoadPresetEntry {
  id: number;
  counter?: TrafficLoadCounter;
  detExpression?: string;
}

export interface TrafficLoadResponse {
  counts: TrafficLoadCounterValue[];
  largestFlowPeriodInMinutes?: number;
}

export interface UrbioticaIntegrationMessage {
  id: number;
  organism: IdLabel;
  project?: IdLabel;
  createdAt: number;
  durationMs: number;
  method: string;
  url: string;
  requestPayload?: string;
  responseStatus: number;
  responseData?: string;
  processedAt?: number;
  processingComment?: string;
  keepToAnalyze?: boolean;
  processingError?: string;
  amqpPhenomenonId?: string;
}

export interface UrbioticaIntegrationMessageCriteria {
  dateFrom?: number;
  dateTo?: number;
  beforeId?: number;
  organismId?: number;
  projectId?: number;
  url?: string;
  responseStatus?: number;
  onlyWithErrors?: boolean;
  keepToAnalyze?: boolean;
  processingError?: string;
  responseData?: string;
  requestPayload?: string;
  page?: number;
  pageSize?: number;
}

export interface UrbioticaIntegrationMessagesResponse {
  totalCount: number;
  providedCount: number;
  items: UrbioticaIntegrationMessage[];
}

export interface UrbioticaOrganism {
  id: number;
  tenant?: IdLabel;
  description: string;
  apiAddress: string;
  externalId: string;
  apiUser: string;
  apiPassword: string;
}

export interface UrbioticaProject {
  id: number;
  tenant: IdLabel;
  externalId: string;
  name: string;
  organismId: number;
  organismExternalId: string;
  organismDescription: string;
  dataSyncEnabled: boolean;
  sensorsCount?: number;
  sectionsCount?: number;
  lastPullSyncedDataAt: number;
  syncError?: string;
  amqpConsumerRegistered: boolean;
  amqpReceivedMessagesCount?: number;
  amqpIgnoredMessagesCount?: number;
  amqpQueueName?: string;
  amqpError?: string;
  amqpMissingConfig: boolean;
}

export interface UrbioticaProjectConfig {
  id: number;
  tenant?: IdLabel;
  name: string;
  externalId: string;
  organismDescription: string;
  organismExternalId: string;
  amqpHost: string;
  amqpPort: number;
  amqpUserName: string;
  amqpPassword: string;
  amqpQueueName?: string;
}

export interface User {
  id: number;
  login: string;
  type: UserType;
  name?: string;
  lastName?: string;
  comment?: string;
  email?: string;
  mobilePhone?: string;
  deactivated: boolean;
  expiryTime?: number;
  requirePasswordChange: boolean;
}

export interface UserAppConfigUpdate {
  rootConfigPrefix: string;
  userId: number;
  data: any;
}

export interface UserChangePasswordCommand {
  oldPassword: string;
  newPassword: string;
  newPassword2: string;
}

export interface UserCreateCommand {
  tenant?: IdLabel;
  login: string;
  name?: string;
  lastName?: string;
  comment?: string;
  mobilePhone?: string;
  email?: string;
  integrationUser: boolean;
}

export interface UserDesktopState {
  id: number;
  name: string;
  state: string;
}

export interface UserDesktopStateMetadata {
  id: number;
  name: string;
  restoreAfterLogin: boolean;
}

export interface UserExternalApiInfo {
  enabled: boolean;
  apiToken?: string;
}

export interface UserGlobalPrivsUpdateCommand {
  userId: number;
  privOperations: UserPrivOperation[];
}

export interface UserGroup {
  id: number;
  name: string;
  kind: UserGroupKind;
  users: IdLabel[];
}

export enum UserGroupKind {
  ItcServiceRequestHandling = 1,
}

export const userGroupKindDescription = {
  [UserGroupKind.ItcServiceRequestHandling]: 'Obsługa zgłoszeń serwisowych',
};

export interface UserManage {
  id: number;
  tenants: IdLabel[];
  hasForeignTenant: boolean;
  createdAt?: number;
  lastActivity?: number;
  login: string;
  type: UserType;
  lastSuccessLogin?: number;
  lastPasswordChange?: number;
  name?: string;
  lastName?: string;
  comment?: string;
  email?: string;
  mobilePhone?: string;
  preferredLang?: Lang;
  preferredLangManuallySet: boolean;
  deactivated: boolean;
  expiryTime?: number;
  requirePasswordChange: boolean;
}

export interface UserManageListEntry {
  id: number;
  tenants: IdLabel[];
  singleTenant?: IdLabel;
  login: string;
  name?: string;
  lastName?: string;
  comment?: string;
  email?: string;
  type: UserType;
  deactivated: boolean;
  expiryTime?: number;
}

export interface UserObjectAssignManagementData {
  userId: number;
  objectType: ObjectPrivType;
  grantedAccessToAnyObject: boolean;
  isTenantRelatedObject: boolean;
  isTenantPreparedObject: boolean;
  objectPrivCode: string;
  assignedObjectIds: number[];
  restrictedToAssignObjectIds?: number[];
}

export interface UserObjectPrivOperation {
  operation: OperationKind;
  objectId: number;
}

export interface UserObjectsPrivUpdateCommand {
  userId: number;
  objectType: ObjectPrivType;
  privCode: string;
  anyObject: boolean;
  privOperations: UserObjectPrivOperation[];
}

export interface UserPrivOperation {
  operation: OperationKind;
  priv: GlobalPriv;
}

export interface UserSetDeactivationCommand {
  userId: number;
  deactivated: boolean;
  expiryTime?: number;
}

export interface UserTenantAssignCommand {
  operations: UserTenantAssignOperation[];
}

export interface UserTenantAssignOperation {
  operation: OperationKind;
  userId: number;
  tenantId: number;
}

export interface UserTenantEntry {
  id: number;
  login: string;
  type: UserType;
  name?: string;
  lastName?: string;
  deactivated: boolean;
}

export enum UserType {
  super = 1,
  normal = 2,
  integration = 3,
}

export const userTypeDescription = {
  [UserType.super]: 'Globalny administrator',
  [UserType.normal]: 'Użytkownik standardowy',
  [UserType.integration]: 'Użytkownik techniczny do integracji',
};

export interface UserUpdateContactCommand {
  mobilePhone?: string;
  email?: string;
}

export interface UserUpdateDetailsCommand {
  id: number;
  name?: string;
  lastName?: string;
  comment?: string;
  mobilePhone?: string;
  email?: string;
  preferredLang?: Lang;
  preferredLangManuallySet: boolean;
}

export interface VehiceOnCrsResponse {
  totalCount: number;
  providedCount: number;
  items: VehicleOnCrsTrace[];
}

export interface VehicleManage {
  id: number;
  systemId: number;
  vehicleType: VehicleType;
  modemNr: number;
  description: string;
  plate: string;
}

export interface VehicleMetadata {
  posLogSource: VehiclePosLogSource;
  busId?: number;
  spvId?: number;
  posLogVehId: number;
  name: string;
  plate: string;
  date: string;
}

export interface VehicleMetadataCriteria {
  posLogSource: VehiclePosLogSource;
  vehType?: VehicleTypeFilter;
  dateFrom?: string;
  dateTo?: string;
  onlyWithHistory?: boolean;
  onlyWithPriority?: boolean;
  encounteredCrsIds?: number[];
  withPriorityOnSelectedCrs?: boolean;
}

export interface VehicleOnCrsTrace {
  id: number;
  crsId: number;
  crsName: string;
  valid: boolean;
  enterZoneId: number;
  exitZoneId: number;
  comment: string;
  wh: number;
  busLineId: number;
  busLine: string;
}

export interface VehicleOnCrsTraceCriteria {
  crsId?: number;
  enterZoneId?: number;
  exitZoneId?: number;
  busLineId?: number;
  invalidOnly?: boolean;
  dateFrom?: number;
  dateTo?: number;
}

export interface VehicleOnCrsTraceDetails {
  id: number;
  nextCrsTraceId?: number;
  previousCrsTraceId?: number;
  nextCriteriaTraceId?: number;
  previousCriteriaTraceId?: number;
  criteriaPrevAndNextTracesProvided: boolean;
  crsId: number;
  crsName: string;
  enterZoneId: number;
  exitZoneId: number;
  busLineId: number;
  busLine: string;
  vehicleId: number;
  wh: number;
  busDelay: number;
  valid: boolean;
  ride: string;
  comment: string;
  points: VehicleOnCrsTraceDetailsPoint[];
  busZones: Zone[];
  crsGpsCoords?: GpsCoords;
}

export interface VehicleOnCrsTraceDetailsPoint {
  wh: number;
  lat: number;
  lng: number;
}

export interface VehicleOnCrsTraceDetailsQuery {
  traceId: number;
  criteriaContext?: VehicleOnCrsTraceCriteria;
}

export interface VehiclePosLogDetailedPoint {
  wh: number;
  lat: number;
  lng: number;
  status: number;
  crsId?: number;
  crsName?: string;
}

export interface VehiclePosLogDetailedTrackData {
  vehicle: VehicleMetadata;
  dateFrom: number;
  dateTo: number;
  points: VehiclePosLogDetailedPoint[];
}

export enum VehiclePosLogSource {
  VehiclePosLog = 0,
  SpVehOnCrsTravelLog = 1,
}

export enum VehiclePosLogStatus {
  StandardPass = 0,
  SpecialVehicleRequiresPriority = 1,
}

export const vehiclePosLogStatusDescription = {
  [VehiclePosLogStatus.StandardPass]: 'Normalny przejazd',
  [VehiclePosLogStatus.SpecialVehicleRequiresPriority]: 'Pojazd specjalny żąda priorytetu',
};

export interface VehiclePosLogTrackCrsEncounter {
  wh: number;
  vehId: number;
  crsId: number;
}

export interface VehiclePosLogTrackDataCriteria {
  posLogSource: VehiclePosLogSource;
  vehId: number;
  dateFrom: number;
  dateTo: number;
}

export interface VehiclePosLogTrackList {
  vehicle: VehicleMetadata;
  tracks: VehiclePosLogTrackListEntry[];
  lastLoggedDate?: number;
  crsEncounters?: VehiclePosLogTrackCrsEncounter[];
}

export interface VehiclePosLogTrackListCriteria {
  posLogSource: VehiclePosLogSource;
  vehicleId: number;
  date: string;
  encounterCrsIds?: number[];
}

export interface VehiclePosLogTrackListEntry {
  dateFrom: number;
  dateTo: number;
  duration: number;
  partDescription?: string;
  selectedCrsEncountered?: boolean;
  withPriority?: boolean;
}

export enum VehicleType {
  Unknown = 0,
  Bus = 1,
  TBus = 2,
  Tram = 3,
  Police = 10,
  Firetruck = 11,
  Ambulance = 12,
  ServiceVehicle = 13,
}

export const vehicleTypeDescription = {
  [VehicleType.Unknown]: 'Nieznany',
  [VehicleType.Bus]: 'Autobus',
  [VehicleType.TBus]: 'Trolejbus',
  [VehicleType.Tram]: 'Tramwaj',
  [VehicleType.Police]: 'Policja',
  [VehicleType.Firetruck]: 'Straż pożarna',
  [VehicleType.Ambulance]: 'Ambulans',
  [VehicleType.ServiceVehicle]: 'Pojazd serwisowy',
};

export enum VehicleTypeFilter {
  all = 0,
  bus = 1,
  spv = 2,
}

export const vehicleTypeFilterDescription = {
  [VehicleTypeFilter.all]: 'Dowolny',
  [VehicleTypeFilter.bus]: 'Transport publiczny',
  [VehicleTypeFilter.spv]: 'Pojazd specjalny',
};

export interface VerifyPasswordOfCurrentUserCommand {
  password: string;
}

export interface VerifyPasswordOfCurrentUserResult {
  success: boolean;
}

export enum VideoStreamUrlType {
  Rtsp = 0,
  Mjpg = 1,
}

export interface Vms {
  id: number;
  tenant?: IdLabel;
  protocol: VmsProtocol;
  name: string;
  ip: string;
  port: number;
  networkProtocol: VmsNetworkProtocol;
  networkRetryCount: number;
  networkTimeoutMs: number;
  displayAreaSpecification: VmsDisplayAreaSpecification;
  textEncoding: VmsTextEncoding;
  parkingSpacesDisplayFormat?: string;
  parkingTextAlign: VmsTextAlign;
  parkingFontStyle: VmsFontStyle;
  parkingTextZonePaddingLeftPx: number;
  parkingTextZonePaddingRightPx: number;
  syncDisabled: boolean;
  manualMode: boolean;
  refreshIntervalSeconds: number;
  removed: boolean;
  removedAt?: number;
  gpsLocation: GpsCoords;
  schedules: VmsSchedule[];
  parkingZones: VmsParkingZone[];
}

export enum VmsConnectionPenaltyReason {
  None = 0,
  PoorConnectionQuality = 1,
}

export const vmsConnectionPenaltyReasonDescription = {
  [VmsConnectionPenaltyReason.None]: 'None',
  [VmsConnectionPenaltyReason.PoorConnectionQuality]: 'Słaba jakość połączenia',
};

export enum VmsConnectionQuality {
  Unknown = 0,
  Good = 1,
  Average = 2,
  Poor = 3,
}

export const vmsConnectionQualityDescription = {
  [VmsConnectionQuality.Unknown]: 'Nieznana',
  [VmsConnectionQuality.Good]: 'Dobra',
  [VmsConnectionQuality.Average]: 'Przeciętna',
  [VmsConnectionQuality.Poor]: 'Słaba',
};

export interface VmsDetails {
  id: number;
  tenant: IdLabel;
  name: string;
  gpsLocation: GpsCoords;
  ip: string;
  port: number;
  networkProtocol: VmsNetworkProtocol;
  networkRetryCount: number;
  networkTimeoutMs: number;
  syncDisabled: boolean;
  manualMode: boolean;
  isPowerOn: boolean;
  isPowerOnLastRead: number;
  textToDisplay?: string;
  textToDisplayExpected?: string;
  lastContentChange: number;
  lastCommunication: number;
  lastSyncProcess: number;
  lastSyncProcessSuccess: number;
  workMode: VmsWorkMode;
  workModeStaticText?: string;
  syncStatus: VmsSyncStatus;
  connectionQuality: VmsConnectionQuality;
  activeConnectionPenaltyExpiryTime?: number;
  activeConnectionPenaltyReason?: VmsConnectionPenaltyReason;
  waitingForSync: boolean;
  syncError?: string;
  avgTotalSyncTimeMs: number;
  avgCommandExecuteTimeMs: number;
  parkingZones: VmsDetailsParkingZone[];
}

export interface VmsDetailsParkingZone {
  zoneNumber: number;
  textToDisplay?: string;
  textToDisplayExpected?: string;
  areas: ParkingAreaLabel[];
}

export interface VmsDisplayAreaConfig {
  displayAreaSpecification: VmsDisplayAreaSpecification;
  width: number;
  height: number;
  fullMatrix: boolean;
  parkingZones: VmsParkingZoneConfig[];
}

export enum VmsDisplayAreaSpecification {
  SmallBuiltIn = 10,
  SmallBuiltInX2 = 11,
  SmallBuiltInX3 = 12,
  FullMatrix112X128 = 20,
  FullMatrix48x32 = 30,
}

export const vmsDisplayAreaSpecificationDescription = {
  [VmsDisplayAreaSpecification.SmallBuiltIn]: 'Wbudowany 64x16',
  [VmsDisplayAreaSpecification.SmallBuiltInX2]: 'Wbudowany podwójny 64x16 (128x16)',
  [VmsDisplayAreaSpecification.SmallBuiltInX3]: 'Wbudowany potrójny 64x16 (192x16)',
  [VmsDisplayAreaSpecification.FullMatrix112X128]: 'FullMatrix 112x128',
  [VmsDisplayAreaSpecification.FullMatrix48x32]: 'FullMatrix 48x32',
};

export enum VmsFontStyle {
  F5x5 = 0,
  F6x7 = 1,
  F8x14 = 2,
  F9x15 = 3,
  F9x16 = 4,
  F9x11 = 10,
  FB10x14 = 23,
  FB12x16 = 25,
  FB9x11 = 28,
}

export const vmsFontStyleDescription = {
  [VmsFontStyle.F5x5]: '5x5',
  [VmsFontStyle.F6x7]: '6x7',
  [VmsFontStyle.F8x14]: '8x14',
  [VmsFontStyle.F9x15]: '9x15',
  [VmsFontStyle.F9x16]: '9x16',
  [VmsFontStyle.F9x11]: '9x11',
  [VmsFontStyle.FB10x14]: '10x14 Bold',
  [VmsFontStyle.FB12x16]: '12x16 Bold',
  [VmsFontStyle.FB9x11]: '9x11 Bold',
};

export interface VmsImage {
  id: number;
  width: number;
  height: number;
  base64Data: string;
}

export interface VmsListEntry {
  id: number;
  tenant?: IdLabel;
  protocol: VmsProtocol;
  name: string;
  syncDisabled: boolean;
  removed: boolean;
  removedAt?: number;
}

export interface VmsManualControl {
  vmsId: number;
  manualMode: boolean;
  workMode?: VmsWorkMode;
  workModeStaticText?: string;
  workModeImageId?: number;
}

export interface VmsMapData {
  id: number;
  tenantId: number;
  name: string;
  syncStatus: VmsSyncStatus;
  connectionQuality: VmsConnectionQuality;
  gpsLocation: GpsCoords;
  activeConnectionPenaltyExpiryTime?: number;
}

export enum VmsNetworkProtocol {
  Tcp = 0,
  Udp = 1,
}

export interface VmsParkingZone {
  id: number;
  zoneNumber: number;
  name?: string;
  disabled: boolean;
  parkingAreas: ParkingAreaLabel[];
  spacesAvailableFloorFactor: number;
  vmsImageId?: number;
}

export interface VmsParkingZoneConfig {
  zoneNumber: number;
  x: number;
  y: number;
  width: number;
  height: number;
  supportsBackgroundImage: boolean;
}

export enum VmsProtocol {
  Demo = 0,
  JetFileII = 10,
  VisualedSimplifiedJetFileII = 20,
}

export const vmsProtocolDescription = {
  [VmsProtocol.Demo]: 'Demo',
  [VmsProtocol.JetFileII]: 'JetFileII',
  [VmsProtocol.VisualedSimplifiedJetFileII]: 'Visualed (simplified JetFileII)',
};

export interface VmsSchedule {
  id: number;
  priorityOrder: number;
  enabled: boolean;
  conditions: PriorityScheduleConditions;
  workMode: VmsWorkMode;
  workModeStaticText?: string;
  workModeImageId?: number;
  comment?: string;
}

export interface VmsSnapshot {
  vmsId: number;
  vmsName: string;
  timestamp: number;
  base64SnapshotData?: string;
  error?: string;
}

export interface VmsStats {
  totalCount: number;
  syncDisabledCount: number;
  manualModeCount: number;
  scheduleModeCount: number;
  scheduleModeWithoutScheduleCount: number;
  syncStates: VmsStatsSyncStatusItem[];
  workModes: VmsStatsWorkModeItem[];
  connectionQualities: VmsStatsConnectionQualityItem[];
}

export interface VmsStatsConnectionQualityItem {
  connectionQuality: VmsConnectionQuality;
  count: number;
}

export interface VmsStatsSyncStatusItem {
  status: VmsSyncStatus;
  count: number;
}

export interface VmsStatsWorkModeItem {
  workMode: VmsWorkMode;
  count: number;
}

export interface VmsSyncDiagnosticData {
  vmsId: number;
  avgTotalSyncTimeMs: number;
  avgCommandExecuteTimeMs: number;
  avgCommandExecuteTimeMsExcludingSignificatError: number;
  items: VmsSyncDiagnosticDataItem[];
  lastItem?: VmsSyncDiagnosticDataItem;
}

export interface VmsSyncDiagnosticDataCommand {
  cmd: string;
  executeTimeMs: number;
  errorCode?: number;
  errorDescription?: string;
}

export interface VmsSyncDiagnosticDataItem {
  syncStartedAt: number;
  totalSyncTimeMs: number;
  avgCommandExecuteTimeMs: number;
  excludedAsSevereMeasurementError: boolean;
  syncStatus: VmsSyncStatus;
  syncError?: string;
  commands: VmsSyncDiagnosticDataCommand[];
}

export enum VmsSyncStatus {
  NotRelated = 0,
  SyncDisabled = 1,
  Synced = 10,
  SyncError = 30,
}

export const vmsSyncStatusDescription = {
  [VmsSyncStatus.NotRelated]: 'Nie dotyczy',
  [VmsSyncStatus.SyncDisabled]: 'Synchronizacja wyłączona',
  [VmsSyncStatus.Synced]: 'Ok',
  [VmsSyncStatus.SyncError]: 'Błąd',
};

export interface VmsTestConnection {
  protocol: VmsProtocol;
  ip: string;
  port: number;
  networkProtocol: VmsNetworkProtocol;
}

export interface VmsTestConnectionResult {
  protocol: VmsProtocol;
  networkProtocol: VmsNetworkProtocol;
  ip: string;
  port: number;
  success: boolean;
  errorCode?: number;
  errorMessage?: string;
}

export enum VmsTextAlign {
  Center = 0,
  Left = 1,
  Right = 2,
  Top = 16,
  Bottom = 32,
}

export const vmsTextAlignDescription = {
  [VmsTextAlign.Center]: 'Do środka',
  [VmsTextAlign.Left]: 'Do lewej',
  [VmsTextAlign.Right]: 'Do prawej',
  [VmsTextAlign.Top]: 'Do góry',
  [VmsTextAlign.Bottom]: 'Do dołu',
};

export enum VmsTextEncoding {
  Ascii = 0,
  Iso88591 = 1,
  Iso88592 = 2,
  CodePage437 = 3,
}

export const vmsTextEncodingDescription = {
  [VmsTextEncoding.Ascii]: 'ASCII (7-bit)',
  [VmsTextEncoding.Iso88591]: 'ISO-8859-1',
  [VmsTextEncoding.Iso88592]: 'ISO-8859-2',
  [VmsTextEncoding.CodePage437]: 'Code page 437',
};

export enum VmsWorkMode {
  Detached = 0,
  ParkingAvailableSpaceCount = 10,
  ParkingStaticText = 11,
  ShowStaticText = 20,
  ShowCustomBitmap = 21,
  BlackScreen = 30,
  PowerOff = 40,
}

export const vmsWorkModeDescription = {
  [VmsWorkMode.Detached]: 'Odłączony od systemu',
  [VmsWorkMode.ParkingAvailableSpaceCount]: 'Parkingi - liczba wolnych miejsc',
  [VmsWorkMode.ParkingStaticText]: 'Parkingi - własny tekst',
  [VmsWorkMode.ShowStaticText]: 'Własny tekst',
  [VmsWorkMode.ShowCustomBitmap]: 'Własna bitmapa',
  [VmsWorkMode.BlackScreen]: 'Wygaszenie',
  [VmsWorkMode.PowerOff]: 'Wyłączenie',
};

export interface VmsWorkPeriod {
  dateStart: number;
  dateEnd: number;
  priorityOrder?: number;
  isNoScheduleGap: boolean;
  workMode?: VmsWorkMode;
  workModeStaticText?: string;
  workModeImageId?: number;
  comment?: string;
}

export interface WebClientErrorReport {
  userAgent?: string;
  appVersion?: string;
  details?: string;
}

export interface WebClientMetadataReport {
  appVersion: string;
  nativeWindowMode: boolean;
  windows?: WebClientMetadataReportWindow[];
  activeWindow?: WebClientMetadataReportWindow;
}

export interface WebClientMetadataReportWindow {
  title: string;
}

export interface Zone {
  id: number;
  kind: ZoneKind;
  crsId: number;
  points: GpsCoords[];
  pointsHash: number;
}

export enum ZoneKind {
  bus = 1,
  spv = 2,
}

export interface ZoneUpdateParams {
  zoneId: number;
  points: GpsCoords[];
}
